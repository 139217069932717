import React, { useState, useContext, useEffect } from 'react';
import './LocationSelect.css'

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useTranslation } from 'react-i18next';
import MarketplaceContext from '../../../Context/MarketplaceContext';
import SelectedMarketplaceContext from '../../../Context/SelectedMarketplaceContext';

const LocationSelect = () => {

  const { t } = useTranslation();
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const authToken = localStorage.getItem('authToken');
  const activeMarketplaceId = localStorage.getItem('activeMarketplaceId');
  const changeMarketplace_URL = `${baseUrl}/zumi/api/users/updateuser`;
  const { marketplaceArray } = useContext(MarketplaceContext);

  const { selectedMarketplace, setSelectedMarketplace } = useContext(SelectedMarketplaceContext);

  // F U N C T I O N   T O   S E T   M A R K E T P L A C E 
  useEffect(() => {
    if (activeMarketplaceId) {
      setSelectedMarketplace(activeMarketplaceId);
    }
  }, []);

  const handleChange = (event) => {
    setSelectedMarketplace(event.target.value);
    // Additional logic to handle selection change, e.g., update localStorage
    localStorage.setItem('activeMarketplaceId', event.target.value);
  };

  // F U N C T I O N    T O   P O S T   S E L E C T E D    M A R K E T P L A C E   O N    A P I

  useEffect(() => {
    const channelIdJson = JSON.stringify({ channelId: selectedMarketplace });
    const updateMarketplace = async () => {
      try {
        const response = await fetch(changeMarketplace_URL, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${authToken}`,
            'Content-Type': 'application/json',
          },
          body: channelIdJson,
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
      } catch (err) {
        console.error('Error posting data:', err);
      }
    };

    if (selectedMarketplace) {
      updateMarketplace();
    }
  }, [selectedMarketplace]);


  return (
    <div className="locationSelectMain_cont">
      <Box sx={{ minWidth: 120, }}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">{t('source')}</InputLabel>
          <Select
            style={{ borderRadius: "", border: 'none' }}
            className='locationSelect'
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedMarketplace || ''}
            label={t('source')}
            onChange={handleChange}
          >{
              marketplaceArray.map((item, index) => {
                return (
                  <MenuItem key={index} value={item.channelId}>{item.nickname}</MenuItem>
                )
              })
            }
          </Select>
        </FormControl>
      </Box>
    </div>

  )
}

export default LocationSelect;