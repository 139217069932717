import React, { useState, useEffect } from 'react';
import './PaymentSuccess.css';
import check from '../../../assets/greenCheck.jpg';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const PaymentSuccess = () => {
  const { t } = useTranslation();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const authToken = localStorage.getItem('authToken');
  const userId = localStorage.getItem('userId');
  const paymentFrom = JSON.parse(localStorage.getItem('paymentFrom'));
  const params = new URLSearchParams(window.location.search);
  const accessCode = params.get('AccessCode');
  const subscription_URL = `${baseUrl}/zumi/api/subscriptions`;
  const creditStore_URL = `${baseUrl}/zumi/api/credits/store`;

  const [show, setShow] = useState(false);

  // S E N D  A C C E S S   C O D E
  useEffect(() => {
    if (authToken && paymentFrom) {
      const fetchData = async () => {
        try {
          const response = await fetch(`${baseUrl}/zumi/api/payment/transaction-result?accessCode=${accessCode}`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${authToken}`,
              'Content-Type': 'application/json',
            },
          });
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const result = await response.text();
          if (paymentFrom.paymentFrom === "MyAccount") {
            sendSubscriptionSuccess();
          }
          if (paymentFrom.paymentFrom === "BuyCredits") {
            sendBuyCreditSuccess();
          }
        } catch (error) {
          console.log(error);
        }
      };
      fetchData();
    }
  }, [accessCode]);

  // Set show state to show the success checkmark after a small delay
  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 100); // Adjust timing as needed
  }, []);

  // S E N D   S U B S C R I P T I O N    S U C C E S S
  const sendSubscriptionSuccess = async () => {
    if (!paymentFrom) {
      console.error('No payment data found');
      return;
    }

    const planId = paymentFrom.selectedPlanId;
    const planDuration = paymentFrom.planDuration;

    // Get the current date and time in the required format
    const getFormattedDate = () => {
      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, '0');
      const day = String(now.getDate()).padStart(2, '0');
      const hours = String(now.getHours()).padStart(2, '0');
      const minutes = String(now.getMinutes()).padStart(2, '0');
      const seconds = String(now.getSeconds()).padStart(2, '0');
      return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
    };

    // Calculate the end date based on the plan duration
    const calculatePlanEndDateTime = (duration) => {
      const now = new Date();
      if (duration === 'year') {
        now.setFullYear(now.getFullYear() + 1);
      } else if (duration === 'month') {
        now.setMonth(now.getMonth() + 1);
      }

      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, '0');
      const day = String(now.getDate()).padStart(2, '0');
      const hours = String(now.getHours()).padStart(2, '0');
      const minutes = String(now.getMinutes()).padStart(2, '0');
      const seconds = String(now.getSeconds()).padStart(2, '0');
      return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
    };

    // Set plan start and end dates
    const startDate = getFormattedDate();
    const endDate = calculatePlanEndDateTime(planDuration);

    const subscriptionData = {
      plan_id: planId,
      start_date: startDate,
      end_date: endDate,
    };

    try {
      const response = await fetch(subscription_URL, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${authToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(subscriptionData),
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.error('Subscription error:', errorData);
      } else {
        const data = await response.json();
        console.log('Subscription Success:', data);
        localStorage.removeItem('paymentFrom')
      }
    } catch (err) {
      console.error('Error posting subscription data:', err);
    }
  };

  // S E N D   C R E D I T   S T O R E   D A T A
  const sendBuyCreditSuccess = async () => {
    if (!paymentFrom) {
      console.error('No payment data found');
      return;
    }

    const credits = paymentFrom.credits;

    // Get the current date in yyyy-mm-dd format
    const getFormattedDate = () => {
      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, '0');
      const day = String(now.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    };

    // Calculate the end date as one year from the current date
    const calculatePlanEndDateTime = () => {
      const now = new Date();
      now.setFullYear(now.getFullYear() + 1); // Add one year to the current date

      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, '0');
      const day = String(now.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`; // Return in yyyy-mm-dd format
    };

    // Set plan start and end dates
    const startDate = getFormattedDate();
    const endDate = calculatePlanEndDateTime();


    const creditStoreData = {
      userId: userId,
      credits: credits,
      available_credits: credits,
      start_date: startDate,
      end_date: endDate,
    };

    try {
      const response = await fetch(creditStore_URL, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${authToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(creditStoreData),
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.error('Subscription error:', errorData);
      } else {
        const data = await response.json();
        console.log('Subscription Success:', data);
        localStorage.removeItem('paymentFrom');
      }
    } catch (err) {
      console.error('Error posting subscription data:', err);
    }


  };

  return (
    <div className="paySuccessMain_cont">
      <div className="paySuccess_cont">
        <div className={`paySuccessImg_cont centerElm ${show ? 'show' : ''}`}>
          <img src={check} alt="Payment success check" />
        </div>
        <p className="paySuccessPara">{t("paymentSuccess")}</p>
        <p style={{ fontSize: "15px" }}>
          {t("paymentSuccessPara")}
        </p>
        <Link className="btnNotFill paySuccessBtn" to="/home/my-account">
          My Account
        </Link>
      </div>
    </div>
  );
};

export default PaymentSuccess;
