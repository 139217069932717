import React, { useState, useEffect } from 'react';
import './ConnectStep1.css';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
// import { useStepContext } from '@mui/material';
import { Link } from 'react-router-dom';
import Breadcrumbs from '../../../Toolbar/Breadcrumbs';


const ConnectStep1 = () => {
  const { t } = useTranslation();

  const [selectedDial, setSelectedDial] = useState(91);
  const [whatsappNumber, setWhatsappNumber] = useState();
  const [businessName, setBusinessName] = useState('');
  const [websiteUrl, setWebsiteUrl] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('')

  //  S E T    M A N D A T O R Y    E R R O R 
  const mandatoryError = !selectedDial || !whatsappNumber || !businessName || !websiteUrl || !selectedCategory;

  const [connectStep1Data, setConnectStep1Data] = useState({
    dial: '',
    whatsappNumber: '',
    displayName: '',
    websiteUrl: '',
    businessCategory: '',
  })
  const [dialItems, setDialItems] = useState([
    { id: 1, value: 91, name: 'IN' },
    { id: 2, value: 61, name: 'AUS' },
  ])
  const [categoryItems, setCategoryItems] = useState([
    { id: 1, value: "automobiles", name: 'Automobiles' },
    { id: 2, value: "ecommerce", name: 'Ecommerce' },
    { id: 3, value: "IT", name: 'IT' },
    { id: 4, value: "others", name: 'Others' }
  ])

  const handleDialChange = (e) => {
    setSelectedDial(e.target.value);
  }
  const handleWhatsappChange = (e) => {
    setWhatsappNumber(e.target.value);
  }
  const handleBusinessNameChange = (e) => {
    setBusinessName(e.target.value);
  }
  const handleWebsiteUrlChange = (e) => {
    setWebsiteUrl(e.target.value);
  }
  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
  }

  useEffect(() => {
    setConnectStep1Data({
      dial: selectedDial,
      whatsappNumber: whatsappNumber,
      businessName: businessName,
      websiteUrl: websiteUrl,
      businessCategory: selectedCategory,
    })
  }, [selectedDial, whatsappNumber, businessName, websiteUrl, selectedCategory])

  const handleSave = (prevData) => {

    console.log(connectStep1Data);

    setSelectedDial('');
    setWhatsappNumber('');
    setBusinessName('');
    setWebsiteUrl('');
    setSelectedCategory('');
  }


  return (
    <div className='connectStep1Main_cont'>
      <Breadcrumbs />
      <div className="connectStepSub_Cont">
        <span className='stepSpan'>Step 1/2</span>
        <div className="connectStep1Cont">
          <h4>{t('connectStep1Head1')} <span className="asterisk">*</span></h4>
          <p className='smallPara'>{t('connectStep1Para1')}</p>

          <div className="connectStep1Inputs_cont">
            <Box className="dialSelectStep1">
              <FormControl fullWidth>
                <Select
                  className=''
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedDial}
                  name="dial"
                  onChange={handleDialChange}
                  displayEmpty
                  renderValue={(selected) => {
                    if (selected === 91) {
                      return "IN";
                    }
                    if (selected === 61) {
                      return "AUS";
                    }
                    return selected;
                  }}
                >{
                    dialItems.map((item) => {
                      return (
                        <MenuItem key={item.id} value={item.value}>{item.name}</MenuItem>
                      )
                    })
                  }
                </Select>
              </FormControl>
            </Box>

            <Box
              className='connectStep1Input'
              component="form"
              sx={{
                '& > :not(style)': { width: '100%', background: "#fff" },
              }}
              noValidate
              autoComplete="off"
            >
              <TextField
                type='number'
                InputLabelProps={{ style: { display: 'none' } }}
                name="segmentName"
                id="outlined-basic"
                label=""
                placeholder={t("connectStep1Placeholder1")}
                variant="outlined"
                value={whatsappNumber}
                onChange={handleWhatsappChange}
              />
            </Box>
          </div>
        </div>
        <div className="connectStep1Cont">
          <h4>{t('connectStep1Head2')} <span className="asterisk">*</span></h4>
          <p className='smallPara'>{t('connectStep1Para2')}</p>
          <div className="connectStep1Inputs_cont">
            <Box
              className='connectStep1Input'
              component="form"
              sx={{
                '& > :not(style)': { width: '100%', background: "#fff" },
              }}
              noValidate
              autoComplete="off"
            >
              <TextField
                InputLabelProps={{ style: { display: 'none' } }}
                name="segmentName"
                id="outlined-basic"
                label=""
                placeholder={t("connectStep1Placeholder2")}
                variant="outlined"
                value={businessName}
                onChange={handleBusinessNameChange}
              />
            </Box>
          </div>
        </div>
        <div className="connectStep1Cont">
          <h4>{t('connectStep1Head3')} <span className="asterisk">*</span></h4>
          <p className='smallPara'>{t('connectStep1Para3')}</p>
          <div className="connectStep1Inputs_cont">
            <Box
              className='connectStep1Input'
              component="form"
              sx={{
                '& > :not(style)': { width: '100%', background: "#fff" },
              }}
              noValidate
              autoComplete="off"
            >
              <TextField
                InputLabelProps={{ style: { display: 'none' } }}
                name="segmentName"
                id="outlined-basic"
                label=""
                placeholder={t("connectStep1Placeholder3")}
                variant="outlined"
                value={websiteUrl}
                onChange={handleWebsiteUrlChange}
              />
            </Box>
          </div>
        </div>
        <div className="connectStep1Cont">
          <h4>{t('connectStep1Head4')} <span className="asterisk">*</span></h4>
          <p className='smallPara'>{t('connectStep1Para4')}</p>

          <div className="connectStep1Inputs_cont">

            <Box className="connectStep1CategoryBox">
              <FormControl fullWidth>
                <Select
                  className=''
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedCategory}
                  name="category"
                  onChange={handleCategoryChange}
                  displayEmpty
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return <em>{t("connectStep1Placeholder4")}</em>;
                    }
                    else {
                      return selected
                    }
                  }}
                >{
                    categoryItems.map((item) => {
                      return (
                        <MenuItem key={item.id} value={item.value}>{item.name}</MenuItem>
                      )
                    })
                  }
                </Select>
              </FormControl>
            </Box>
          </div>
        </div>

        <div className="connectBtn_cont">

        </div>
        {/* <Link to="/whatsapp/connect-account/connect-step1/connect-step2" disabled={!selectedDial || !whatsappNumber || !businessName || !websiteUrl || !selectedCategory} onClick={handleSave} className='connectNowBtn'>Next Step</Link> */}
        <Link to="/home/whatsapp/connect-account/connect-step1/connect-step2" >
          <button className='btnFill' disabled={!selectedDial || !whatsappNumber || !businessName || !websiteUrl || !selectedCategory} onClick={handleSave}>Next Step</button>
        </Link>
        {mandatoryError && (
          <p style={{ color: 'red', fontSize: "12px", marginTop: "10px" }}>{t("mandatory")}</p>
        )}
      </div>
    </div>
  )
}

export default ConnectStep1;