import React, { useState } from "react";
import SegmentContext from "./SegmentContext";

const SegmentContextProvider = ({ children }) => {

  const [segmentArray, setSegmentArray] = useState([]);

  return (
    <SegmentContext.Provider value={{ segmentArray, setSegmentArray }}>
      {children}
    </SegmentContext.Provider>
  )

}

export default SegmentContextProvider;