import React, { useState, useEffect, useRef, useContext } from 'react';
import './CreateSegment.css'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import CloseIcon from '@mui/icons-material/Close';
import SegmentContext from '../../../../Context/SegmentContext';
import { useTranslation } from 'react-i18next';

const CreateSegment = ({ updateSegmentsData, closeModal }) => {

  const { t } = useTranslation();
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const authToken = localStorage.getItem('authToken');
  const { segmentArray } = useContext(SegmentContext);
  const createSegment_URL = `${baseUrl}/zumi/api/createsegment`;
  const [updateSegments, setUpdateSegments] = useState(false);

  const endOfListRef = useRef(null);
  const [hasCapitalLetter, setHasCapitalLetter] = useState(false);
  const [nameError, setNameError] = useState(false);

  const filterItems = [
    { id: 1, value: "name", name: 'Name' },
    { id: 2, value: "phone", name: 'Phone' },
    { id: 3, value: "country", name: 'Country' },
    { id: 4, value: "city", name: 'City' },
    { id: 5, value: "zipcode", name: 'Zip code' },
  ];

  const operationItems = {
    name: [
      { id: 1, value: "=", name: "Equals" },
      { id: 2, value: "like", name: "Like" },
    ],
    phone: [
      { id: 3, value: "=", name: "Equals" },
    ],
    city: [
      { id: 4, value: "=", name: "Equals" },
    ],
    zipcode: [
      { id: 5, value: "=", name: "Equals" },
    ],
    country: [
      { id: 6, value: "=", name: "Equals" },
    ]
  }



  // AUTOFOCUS 
  const inputRef = useRef(null);
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);


  // T O G G L E   B U T T O N

  const leftClick = () => {
    const ToggleBtn = document.querySelector('#ToggleBtn');
    ToggleBtn.style.left = '0'
  }

  const rightClick = () => {
    const ToggleBtn = document.querySelector('#ToggleBtn');
    ToggleBtn.style.left = '50%'
  }

  // Initialize state with empty criteria
  const [newSegmentData, setNewSegmentData] = useState({
    segmentName: '',
    description: '',
    criteria: [
      { column: '', value: '', operation: '' } // Initialize with empty values
    ],
  });

  // H A N D L E   S E G M E N T   N A M E
  const handleSegmentNameChange = (e) => {

    const name = e.target.name;
    let value = e.target.value;

    if (name === 'segmentName') {
      value = value.replace(/[-\s]+/g, '_');
      value = value.toLowerCase();
    }

    // checking template name already exists or not
    const nameExists = segmentArray.some(item => item.segmentName === value);
    if (nameExists) {
      setNameError('Segment name already exists');
    } else {
      setNameError('');
    }
    setNewSegmentData({
      ...newSegmentData,
      segmentName: value,
    });
  };

  // H A N D L E   D E S C R I P T I O N
  const handleDescriptionChange = (e) => {
    const value = e.target.value;
    setNewSegmentData({
      ...newSegmentData,
      description: value,
    });
  };

  // H A N D L E   C R I T E R I A 
  const handleCriteriaChange = (index, e) => {
    const { name, value } = e.target;
    const updatedCriteria = newSegmentData.criteria.map((criterion, i) =>
      i === index ? { ...criterion, [name]: value } : criterion
    );

    setNewSegmentData({
      ...newSegmentData,
      criteria: updatedCriteria,
    });
  };

  const handleAddCriteria = () => {
    if (newSegmentData.criteria.length < 4) {
      setNewSegmentData({
        ...newSegmentData,
        criteria: [...newSegmentData.criteria, { column: '', value: '', operation: '' }],
      });
    }
  };

  // R E M O V E  A   C R I T E R I A 
  const handleRemoveCriteria = (index) => {
    setNewSegmentData({
      ...newSegmentData,
      criteria: newSegmentData.criteria.filter((_, i) => i !== index),
    });
  };


  // S C R O L L   T O   B O T T O M
  useEffect(() => {
    if (endOfListRef.current) {
      endOfListRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }, [newSegmentData.criteria]);


  //  S E T   M A N D A T O R Y    E R R O R 
  const mandatoryError = !newSegmentData.segmentName || !newSegmentData.description;


  // H A N D L E   S U B M I T
  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log("newSegmentData:", newSegmentData);
    try {
      const response = await fetch(createSegment_URL, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${authToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newSegmentData),
      });
      if (response.ok) {
        console.log('Segment created successfully');
        setUpdateSegments(!updateSegments);
      } else {
        console.error('Failed to add ');
      }
    } catch (error) {
      console.error('Error:', error);
    }
    finally {
      updateSegmentsData(updateSegments);
      setUpdateSegments(!updateSegments);
      setNewSegmentData({
        segmentName: '',
        description: '',
        criteria: [
          { column: '', value: '', operation: '' } // Initialize with empty values
        ],
      });
      closeModal();
    }
  };



  // const handleSubmit = (e) => {
  //   e.preventDefault();

  //   // Convert newSegmentData keys and values to lowercase
  //   const lowerCaseData = convertKeysAndValuesToLowerCase(newSegmentData);
  //   const jsonData = JSON.stringify(lowerCaseData);
  //   console.log(jsonData);

  //   // const jsonData = JSON.stringify(newSegmentData);
  //   // console.log(jsonData);

  //   fetch(createSegment_URL, {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'Authorization': `Bearer ${authToken}`, // Add the Authorization header

  //     },
  //     body: JSON.stringify(newSegmentData, {
  //       method: 'GET',
  //       headers: {
  //         'Authorization': `Bearer ${authToken}`, // Add the Authorization header
  //         'Content-Type': 'application/json' // Set content type if necessary
  //       }
  //     })
  //   }).then(response => {
  //     if (response.ok) {
  //       console.log("Segment Created Successfully.")
  //     } else {
  //       let message = `Error: ${response.status} ${response.statusText}`;
  //       alert(message);
  //       throw new Error(message);
  //     }
  //   });

  //   setNewSegmentData({
  //     segmentName: '',
  //     criteria: [
  //       { column: '', value: '', operation: '' } // Initialize with empty values
  //     ],
  //   });
  //   closeModal();
  // };




  return (
    <div className="segmentFormMain" style={{ width: "100%", height: "100%" }}>
      <div className="newSegmentHeader">
        <div className="templateMessage_cont">
          <span className='templateMessage'>
            {t('newSegment')}
          </span>

        </div>
        <div className="closeBtn_cont" role="button" aria-label="Close" onClick={closeModal}>
          <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" className="bi bi-x crossSvg" viewBox="0 0 16 16">
            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
          </svg>
        </div>
      </div>

      <div className="createSegmentMain_cont">
        <div className="segmentNameInput_cont">
          <div className="segmentHeadingInputCont">
            <div className="uploadHeading_cont segmentNameHeading">
              {t('segmentName')} <span className="asterisk">*</span>
            </div>
            <Box
              className='segmentNameBox'
              component="form"
              sx={{
                '& > :not(style)': { width: '100%', background: "#fff" },
              }}
              noValidate
              autoComplete="off"
            >
              <TextField
                inputRef={inputRef}
                InputLabelProps={{ style: { display: 'none' } }}
                autoFocus
                variant="outlined"
                name="segmentName"
                placeholder={t('segmentPlaceholder')}
                value={newSegmentData.segmentName}
                onChange={handleSegmentNameChange}
                id="outlined-basic"
              />
            </Box>
          </div>

          <div className="createSegErrorCont">
            {
              nameError && <div className="spaceErrorCont">
                <p style={{ color: 'red', fontSize: "12px" }}>{nameError}</p>
              </div>
            }
          </div>
        </div>

        <div className="segmentDesc_cont">
          <div className="segmentHeadingInputCont">
            <div className="uploadHeading_cont segmentNameHeading">
              {t('description')} <span className="asterisk">*</span>
            </div>
            <Box
              className='segmentNameBox'

              component="form"
              sx={{
                '& > :not(style)': { width: '100%', background: "#fff" },
              }}
              noValidate
              autoComplete="off"
            >
              <TextField
                InputLabelProps={{ style: { display: 'none' } }}
                name="description"
                value={newSegmentData.description} onChange={handleDescriptionChange} id="outlined-basic" label="" placeholder="Write description..." variant="outlined" />
            </Box>
          </div>
        </div>

        <div className="segmentInputsMain_cont">
          {newSegmentData.criteria.map((criterion, index) => (
            <div key={index} className="criteria_row">
              <div className="removeCriteria_cont">
                <CloseIcon className="removeCriteriaSvg" onClick={() => handleRemoveCriteria(index)} />
              </div>
              <div className="criteriaRow_cont">
                <div className="criteriaRowCont">
                  <div className="uploadHeading_cont">
                    {t('filter')}
                  </div>
                  <div className="segmentInput_cont">
                    {/* <Select
                      className='filterSelect'
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="column"
                      value={criterion.column}
                      onChange={(e) => handleCriteriaChange(index, e)}
                      displayEmpty
                      renderValue={(selected) => {
                        if (selected.length === 0) {
                          return <em>Select Filter</em>;
                        }
                        return selected.charAt(0).toUpperCase() + selected.slice(1).toLowerCase();
                      }}
                    // MenuProps={{
                    //   PaperProps: {
                    //     style: {
                    //       maxHeight: 200, // Set your desired height
                    //       overflowY: 'auto',
                    //     },
                    //   },
                    // }}
                    >
                      {
                        filterItems.map((item) => {
                          return (
                            <MenuItem key={item.id} value={item.value}>{item.name}</MenuItem>
                          )
                        })
                      }
                    </Select> */}

                    <Select
                      className='filterSelect'

                      name="column"
                      value={criterion.column}
                      onChange={(e) => handleCriteriaChange(index, e)}
                      displayEmpty
                      renderValue={(selected) => {
                        if (selected.length === 0) {
                          return <em>Select Filter</em>;
                        }
                        return selected.charAt(0).toUpperCase() + selected.slice(1).toLowerCase();
                      }}
                    >
                      {filterItems
                        .filter(item => !newSegmentData.criteria.some(c => c.column === item.value)) // Exclude already selected columns
                        .map(item => (
                          <MenuItem key={item.id} value={item.value}>{item.name}</MenuItem>
                        ))}
                    </Select>

                  </div>
                </div>
                <div className="criteriaRowCont">
                  <div className="uploadHeading_cont">
                    {t('value')}
                  </div>
                  <div className="segmentInput_cont">
                    <TextField
                      style={{ width: "100%" }}
                      InputLabelProps={{ style: { display: 'none' } }}
                      name="value"
                      id="outlined-basic" label=""
                      placeholder="Value"
                      variant="outlined"
                      value={criterion.value}
                      onChange={(e) => handleCriteriaChange(index, e)}
                    />
                  </div>
                </div>
                <div className="criteriaRowCont">
                  <div className="uploadHeading_cont">
                    {t('action')}
                  </div>
                  <div className="segmentInput_cont">
                    <Select
                      className='filterSelect'
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="operation"
                      value={criterion.operation}
                      onChange={(e) => { handleCriteriaChange(index, e) }}
                      disabled={!criterion.value}
                      displayEmpty
                      renderValue={(selected) => {
                        if (selected.length === 0) {
                          return <em>Select Action</em>;
                        }
                        if (selected === "=") {
                          return "Equals";
                        }
                        return selected.charAt(0).toUpperCase() + selected.slice(1).toLowerCase();
                      }}
                    >
                      {criterion.value && operationItems[criterion.column]?.map((item) => (
                        <MenuItem key={item.id} value={item.value}>{item.name}</MenuItem>
                      ))}
                    </Select>
                  </div>
                </div>
              </div>


            </div>
          ))}

          <div className="addCriteria_cont">
            {newSegmentData.criteria.length < 4 && (
              <button
                type="button"
                className='btnNotFill'
                onClick={handleAddCriteria}
              >
                {t('addCriteria')}
              </button>
            )}
            {/* <button type="button" className='btnNotFill' onClick={handleAddCriteria} disabled={newSegmentData.criteria.length >= 4}>Add Criteria</button> */}
          </div>
          <div className="createSegmentBtn_cont">
            {mandatoryError && (
              <p style={{ color: 'red', fontSize: "12px", }}>Please fill the mandatory fields.</p>
            )}
            <button className='btnFill' disabled={!newSegmentData.segmentName || nameError} onClick={handleSubmit}>{t('save')}</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateSegment;
