// ThemeContext.js
import React, { createContext, useState } from 'react';

export const BrandLogoContext = createContext();

export const BrandLogoProvider = ({ children }) => {
  const [brandLogo, setBrandLogo] = useState('');
  return (
    <BrandLogoContext.Provider value={{ brandLogo, setBrandLogo }}>
      {children}
    </BrandLogoContext.Provider>
  );
};
