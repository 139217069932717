import React, { useState, useEffect, useContext } from 'react';
import './Revenue.css';
import Breadcrumbs from '../../Toolbar/Breadcrumbs';
import tableStyles from './TableStyles';
import LocationSelect from '../../Dashboard/LocationSelect/LocationSelect';
import RevenueChart from './RevenueChart/RevenueChart';
import ContactTableLoader from '../../Loaders/ContactTableLoader/ContactTableLoader';
import DataTable from 'react-data-table-component';

import { useTranslation } from 'react-i18next';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextField } from '@mui/material';
import RevenueContext from '../../../Context/RevenueContext';
import TimelineIcon from '@mui/icons-material/Timeline';
import SelectedMarketplaceContext from '../../../Context/SelectedMarketplaceContext';


const Revenue = () => {

  const { t } = useTranslation();
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const { selectedMarketplace } = useContext(SelectedMarketplaceContext);

  const authToken = localStorage.getItem('authToken');
  const [revenueData, setRevenueData] = useState([]);

  const [totalQuantity, setTotalQuantity] = useState();
  const [totalValue, setTotalValue] = useState();
  const [revnueLoader, setRevenueLoader] = useState(true);

  const [currentDate, setCurrentDate] = useState(null);
  const [pastDate, setPastDate] = useState(null);
  const [weekDate, setWeekDate] = useState();

  const [roughStartDate, setRoughStartDate] = useState(null);
  const [roughEndDate, setRoughEndDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [combinedDate, setCombinedDate] = useState(null);

  const { revenueChartArray, setRevenueChartArray } = useContext(RevenueContext);

  const columns = [
    {
      name: t("marketplace"),
      selector: row => row.title,
      width: "120px",
    },
    {
      name: t("sku"),
      selector: row => row.sku,
      width: "170px",
    },
    {
      name: t("asin"),
      selector: row => {
        if (row.asin) {
          return row.asin
        }
        else {
          return row.sku
        }
      },
      width: "170px",
    },
    {
      name: t("orderId"),
      selector: row => row.orderId,
      width: "200px",
    },
    {
      name: t("productInfo"),
      selector: row => row.itemName,
      width: "250px",

    },
    {
      name: t("currencyCode"),
      selector: row => row.currencyCode,
      width: "90px",

    },
    {
      name: t("totalSale"),
      selector: row => row.revenue,
      width: "90px",

    },
    {
      name: t("cogs"),
      selector: row => row.cogs,
      width: "70px",

    },
    {
      name: t("operatingExp"),
      selector: row => row.operatingExpenses,
      width: "90px",

    },
    {
      name: t("promotion"),
      selector: row => row.promotion,
      width: "90px",

    },
    {
      name: t("other"),
      selector: row => row.other,
      width: "90px",

    },
    {
      name: t("netProfit"),
      selector: row => row.netProfit,
      width: "90px",

    },
    {
      name: t("unitsShipped"),
      selector: row => row.unitShipped,
      width: "90px",

    },
    {
      name: t("refund"),
      selector: row => row.refundRate,
      width: "90px",

    },

  ];

  const handleStartChange = (date) => {
    setRoughStartDate(date);
  }
  const handleEndChange = (date) => {
    setRoughEndDate(date);
  }

  // F U N C T I O N   T O   G E T   C U R R E N T   D A T E

  const getCurrentDate = () => {
    const today = new Date();
    today.setDate(today.getDate() - 1);

    // Extract individual components
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const day = today.getDate();
    const month = monthNames[today.getMonth()];
    const year = today.getFullYear();

    // Construct the date string without commas
    return `${month} ${day} ${year}`;
  };

  useEffect(() => {
    setCurrentDate(getCurrentDate());
  }, []);


  // F U N C T I O N   T O   G E T  D A T E   O F   P A S T   7   D A Y S

  const getDateSevenDaysAgo = () => {
    const today = new Date();
    today.setDate(today.getDate() - 7);

    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const day = today.getDate();
    const month = monthNames[today.getMonth()];
    const year = today.getFullYear();

    return `${month} ${day} ${year}`;
    // return new Intl.DateTimeFormat('en-US', options).format(today);
  };

  useEffect(() => {
    setPastDate(getDateSevenDaysAgo());
  }, []);


  // S E T   W E E K   D A T E

  useEffect(() => {
    if (pastDate && currentDate) {
      setWeekDate(pastDate + " - " + currentDate);
    }
  }, [pastDate, currentDate]);


  // F U N T I O N   T O  S E T   R O U G H   D A T E   I N T O   F O R M A T T E D   D A T E

  useEffect(() => {
    if (roughStartDate) {
      const formattedStartDate = roughStartDate.format('MMM D YYYY');
      setStartDate(formattedStartDate);
    }
    if (roughEndDate) {
      const formattedEndDate = roughEndDate.format('MMM D YYYY');
      setEndDate(formattedEndDate);
    }
  }, [roughEndDate, roughStartDate]);

  //  F U N C T I O N   T O   C O M B I N E  D A T E S 
  useEffect(() => {
    if (startDate && endDate) {
      setCombinedDate(startDate + " - " + endDate)
      setWeekDate(null);
    }
  }, [startDate, endDate]);


  // F E T C H    R E V E N U E    T A B L E   D A T A

  useEffect(() => {

    // WHEN NO DATE RANGE IS PASSED
    if (authToken && weekDate) {
      const revenueTable_URL = `${baseUrl}/zumi/api/salesrevenue?dateRange=${weekDate}`;
      const fetchData = async () => {
        setRevenueLoader(true);
        try {
          const response = await fetch(revenueTable_URL, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${authToken}`, // Add the Authorization header
              'Content-Type': 'application/json',
            },
          });
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const result = await response.json();
          setRevenueData(result);
        } catch (error) {
          console.log(error);
        } finally {
          setRevenueLoader(false);
        }
      };
      fetchData();
    }


    // WHEN DATE RANGE IS PASSED

    if (authToken && combinedDate) {
      const revenueTable_URL = `${baseUrl}/zumi/api/salesrevenue?dateRange=${combinedDate}`;
      const fetchData = async () => {
        setRevenueLoader(true);
        try {

          const response = await fetch(revenueTable_URL, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${authToken}`,
              'Content-Type': 'application/json',
            },
          });
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const result = await response.json();
          setRevenueData(result);
        } catch (error) {
          console.log(error);
        } finally {
          setRevenueLoader(false);
        }
      };
      fetchData();
    }
  }, [authToken, weekDate, combinedDate, selectedMarketplace]);


  // F E T C H    R E V E N U E    C H A R T   D A T A

  useEffect(() => {
    const revenueChart_URL = `${baseUrl}/zumi/api/sales/revenuchart?dateRange=${weekDate}`;
    // WHEN NO DATE RANGE IS PASSED
    if (authToken && weekDate) {
      const fetchData = async () => {
        try {
          const response = await fetch(revenueChart_URL, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${authToken}`, // Add the Authorization header
              'Content-Type': 'application/json',
            },
          });
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const result = await response.json();
          setRevenueChartArray(result);
        } catch (error) {
          console.log(error);
        } finally {
        }
      };
      fetchData();
    }


    // WHEN DATE RANGE IS PASSED
    if (authToken && combinedDate) {
      const revenueChart_URL = `${baseUrl}/zumi/api/sales/revenuchart?dateRange=${combinedDate}`;

      const fetchData = async () => {
        try {
          const response = await fetch(revenueChart_URL, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${authToken}`, // Add the Authorization header
              'Content-Type': 'application/json',
            },
          });
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const result = await response.json();
          setRevenueChartArray(result);
        } catch (error) {
          console.log(error);
        } finally {
        }
      };
      fetchData();
    }
  }, [authToken, weekDate, combinedDate, selectedMarketplace]);



  // T O T A L   Q U A N T I T Y

  useEffect(() => {
    if (revenueChartArray) {
      // Calculate the sum of all counts
      const sum = revenueChartArray.reduce((acc, item) => acc + (item.quantity || 0), 0);
      setTotalQuantity(sum);
    }
  }, [revenueChartArray, weekDate, combinedDate]);


  // T O T A L   V A L U E S 

  useEffect(() => {
    if (revenueChartArray) {
      // Calculate the sum of all counts
      const sum = revenueChartArray.reduce((acc, item) => acc + (item.value || 0), 0);
      const roundedSum = parseFloat(sum.toFixed(3));

      setTotalValue(roundedSum);
    }
  }, [revenueChartArray, weekDate, combinedDate]);


  return (
    <div className='revenue_main'>
      <Breadcrumbs />
      <div className="locationMain_cont">
        <LocationSelect />
        <div className="dateRangePickerMain_cont" style={{ display: 'flex', gap: '16px' }}>
          <LocalizationProvider dateAdapter={AdapterDayjs} >
            <DatePicker
              label="Start Date"
              value={roughStartDate}
              onChange={handleStartChange}
              maxDate={dayjs()}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="End Date"
              value={roughEndDate}
              onChange={handleEndChange}
              maxDate={dayjs()}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </div>
      </div>
      {/* <div className="revenueBtn_cont">
        <Link className='btnFill' style={{ marginRight: "20px" }}>Sample Button</Link>
        <Link className="btnFill">Sample Button</Link>
      </div> */}

      <div className="revenueMainChartMain_cont">
        <div className="revenueMainChart_cont">
          <div className="dcont_heading">
            <span>{t("revenueChartHead")}</span>
            <h5>
              {t("quantity")}: <span className="firstrowdata" style={{ marginRight: "20px" }}>{totalQuantity}</span>
              <span>{`   `}</span>
              {t("value")}: <span className="firstrowdata" style={{ marginRight: "20px" }}>{totalValue} {"AUD"}</span>
            </h5>
          </div>
          <div className="revenueMainChart">
            <RevenueChart />
          </div>
          <div className="revenueLegendMain_cont">
            <div className="revenueLegendSub_cont">
              <div className="legend1_cont">
                <div className="legend1">

                </div>
                <div className="legendName">
                  <p>
                    {t("revenueLegend1")}
                  </p>
                </div>
              </div>
              <div className="legend2_cont">
                <div className="legend2">
                  <TimelineIcon style={{ height: "2rem", width: "2rem", color: "rgb(14, 113, 195)" }} />
                </div>
                <div className="legendName">
                  <p>
                    {t("revenueLegend2")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="revenueTableMain_cont">
        <div className='revenueTable_cont'>
          {revnueLoader ? (
            <ContactTableLoader />
          ) : (
            <DataTable
              columns={columns}
              data={revenueData}
              customStyles={tableStyles}
              pagination
            />
          )}
        </div>
      </div>

    </div>
  )
}

export default Revenue;