import React, { useContext } from 'react';
import './Settings.css';
import { ThemeContext } from '../../Context/ThemeContext';

const Settings = () => {
  const { theme, toggleTheme } = useContext(ThemeContext);
  return (

    <div className={`settingsMain_cont ${theme}`}>
      {/* <div className={theme}>
        <h1>Hello, World!</h1>
        <button onClick={toggleTheme}>
          Switch to {theme === 'light' ? 'dark' : 'light'} mode
        </button>
      </div> */}
    </div>

  )
}

export default Settings;