import React, { useEffect, useState } from 'react';
import './NewMarketplace.css';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Alert from '@mui/material/Alert';
import { useTranslation } from 'react-i18next';
import { TextField } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Link } from 'react-router-dom';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
// import AmazonCard from '../../../assets/AmazonCard.png';
// import ShopifyCard from '../../../assets/ShopifyCard.png';
// import EbayCard from '../../../assets/EbayCard.png';


import Carousel from './Carousel/Carousel';

const NewMarketplace = () => {
  const { t } = useTranslation();
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const authToken = localStorage.getItem('authToken');
  const [activeStep, setActiveStep] = useState(0);
  const [newMarketError, setNewMarketError] = useState("");

  const [amazonAuth_URL, setAmazonAuth_URL] = useState("");
  const [amazonLoader, setAmazonLoader] = useState(false);
  const [amazonError, setAmazonError] = useState("");

  const [ebayAuth_URL, setEbayAuth_URL] = useState("");
  const [ebayLoader, setEbayLoader] = useState(false);
  const [ebayError, setEbayError] = useState("");

  const [shopifyAuth_URL, setShopifyAuth_URL] = useState("");
  const [shopifyLoader, setShopifyLoader] = useState(false);
  const [shopifyError, setShopifyError] = useState("");

  const [newMarketplace, setNewMarketplace] = useState({
    region: "",
    marketplaceName: "",
  });

  const amazon_URL = `${baseUrl}/zumi/api/amazon-authorization?locale=en-${newMarketplace.region}`;
  const ebay_URL = `${baseUrl}/zumi/api/ebay-authorization?locale=en-${newMarketplace.region}`;
  // const shopify_URL = `${baseUrl}/zumi/api/shopify-authorization?locale=storeName-${newMarketplace.region}`;

  const [amazonDetails, setAmazonDetails] = useState({
    amazonAccountName: "",
    amazonSellerEmail: "",
  });
  const [ebayDetails, setEbayDetails] = useState({
    ebayAccountName: "",
    ebaySellerEmail: "",
  });
  const [shopifyDetails, setShopifyDetails] = useState({
    shopifyAccountName: "",
    shopifySellerEmail: "",
    shopifyStoreName: ""
  });

  const steps = [
    'Marketplace',
    'Connect Account',
    'Create PPC',
  ];

  const regions = [
    { value: "AU", name: "Australia" },
    { value: "BR", name: "Brazil" },
    { value: "CA", name: "Canada" },
    { value: "EG", name: "Egypt" },
    { value: "FR", name: "France" },
    { value: "DE", name: "Germany" },
    { value: "IN", name: "India" },
    { value: "IT", name: "Italy" },
    { value: "JP", name: "Japan" },
    { value: "MX", name: "Mexico" },
    { value: "NL", name: "Netherlands" },
    { value: "PL", name: "Poland" },
    { value: "SA", name: "Saudi Arabia" },
    { value: "SG", name: "Singapore" },
    { value: "ES", name: "Spain" },
    { value: "SE", name: "Sweden" },
    { value: "TR", name: "Turkey" },
    { value: "GB", name: "UK" },
    { value: "AE", name: "United Arab Emirates" },
    { value: "US", name: "United States" },
  ];

  const marketplaces = [
    { value: "Amazon", name: "Amazon" },
    { value: "eBay", name: "eBay" },
    { value: "Shopify", name: "Shopify" },
  ]

  // H A N D L E    C H A N G E 
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setNewMarketplace((prevData) => ({ ...prevData, [name]: value }));
  }

  // H A N D L E    A M A Z O N    D E T A I L S
  const handleAmazon = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setAmazonDetails((prevData) => ({ ...prevData, [name]: value }))
  }

  // H A N D L E   E B A Y   D E T A I L S
  const handleEbay = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setEbayDetails((prevData) => ({ ...prevData, [name]: value }))
  }

  // H A N D L E    S H O P I F Y   D E T A I L S
  const handleShopify = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setShopifyDetails((prevData) => ({ ...prevData, [name]: value }))
  }


  // -------------------------------------------------------------------------------//

  // G E T   U R L   F O R    A M A Z O N   
  const getAmazonURL = () => {
    localStorage.setItem("amazonAccountName", amazonDetails.amazonAccountName);
    localStorage.setItem("amazonSellerEmail", amazonDetails.amazonSellerEmail);
    if (authToken) {
      const fetchData = async () => {
        setAmazonLoader(true);
        try {
          const response = await fetch(amazon_URL, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${authToken}`, // Add the Authorization header
              'Content-Type': 'application/json' // Set content type if necessary
            }
          });
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const result = await response.json();
          setAmazonAuth_URL(result);
        } catch (error) {
          console.log(error);
        }
      };

      if (!amazonDetails.amazonAccountName || !amazonDetails.amazonSellerEmail) {
        setAmazonError(true);
      }
      else {
        fetchData();
      }
      setTimeout(() => {
        setAmazonError(false);
      }, 2000);
    }
  }
  // R E D I R E C T    T O   A M A Z O N
  useEffect(() => {
    if (amazonAuth_URL) {
      window.location.href = amazonAuth_URL;
    }
  }, [amazonAuth_URL]);

  // -------------------------------------------------------------------------------//

  // G E T   U R L   F R O M   E B A Y
  const getEbayURL = (e) => {
    localStorage.setItem("ebayAccountName", ebayDetails.ebayAccountName);
    localStorage.setItem("ebaySellerEmail", ebayDetails.ebaySellerEmail);
    if (authToken) {
      const fetchData = async () => {
        setEbayLoader(true);
        try {
          const response = await fetch(ebay_URL, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${authToken}`, // Add the Authorization header
              'Content-Type': 'application/json' // Set content type if necessary
            }
          });

          // const text = await response.text(); // Read the response as text
          // console.log(text);
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const result = await response.text();
          setEbayAuth_URL(result);
        }
        catch (error) {
          console.log(error);
        }
      };

      if (!ebayDetails.ebayAccountName || !ebayDetails.ebaySellerEmail) {
        setEbayError(true);
      }
      else {
        fetchData();
      }
      setTimeout(() => {
        setEbayError(false);
      }, 2000);
    }
  }
  // R E D I R E C T    T O   E B A Y
  useEffect(() => {
    if (ebayAuth_URL) {
      window.location.href = ebayAuth_URL;
    }
  }, [ebayAuth_URL]);

  // -------------------------------------------------------------------------------//

  // G E T    U R L   F R O M   S H O P I F Y
  const handleShopifyAuthenticate = () => {
    localStorage.setItem("shopifyAccountName", shopifyDetails.shopifyAccountName);
    localStorage.setItem("shopifySellerEmail", shopifyDetails.shopifySellerEmail);
    localStorage.setItem("shopifyStoreName", shopifyDetails.shopifyStoreName);

    const shopify_URL = `${baseUrl}/zumi/api/shopify-authorization?storeName=${shopifyDetails.shopifyStoreName}`;
    if (authToken) {
      const fetchData = async () => {
        setEbayLoader(true);
        try {
          const response = await fetch(shopify_URL, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${authToken}`, // Add the Authorization header
              'Content-Type': 'application/json' // Set content type if necessary
            }
          });

          // const text = await response.text(); // Read the response as text
          // console.log(text);
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const result = await response.text();
          setShopifyAuth_URL(result);
        }
        catch (error) {
          console.log(error);
        }
      };

      if (!shopifyDetails.shopifyAccountName || !shopifyDetails.shopifySellerEmail || !shopifyDetails.shopifyStoreName) {
        setShopifyError(true);
      }
      else {
        fetchData();
      }
      setTimeout(() => {
        setShopifyError(false);
      }, 2000);
    }

  }

  // R E D I R E C T    T O   S H O P I F Y
  useEffect(() => {
    if (shopifyAuth_URL) {
      window.location.href = shopifyAuth_URL;
    }
  }, [shopifyAuth_URL]);

  // H A N D L E  N E X T   B U T T O N
  const handleNext = (e) => {
    e.preventDefault();
    if (!newMarketplace.region || !newMarketplace.marketplaceName) {
      setNewMarketError(true);
      setTimeout(() => {
        setNewMarketError(false);
      }, 2000);
    }
    else {
      localStorage.setItem("marketRegion", newMarketplace.region);
      setActiveStep((prev) => prev + 1);
    }
  }

  // H A N D L E   B A C K   B U T T O N
  const handleBack = (e) => {
    e.preventDefault();
    setActiveStep((prev) => prev - 1);
  }


  return (
    <div className='newMarketMain_cont'>
      <div className="newMarketSub_cont">
        <div className="newMarketCont1">
          <div className="newMarketHead_cont">
            <h1>{t('newMarketplace')}</h1>
          </div>
          <div className="newMarketCarousel_cont">
            <Carousel />
          </div>
        </div>
        <div className="newMarketCont2">

          {/* S T E P P E R */}
          <div className="stepper_cont">
            <Stepper className='marketStepper' activeStep={activeStep} alternativeLabel >
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </div>

          {/* F I R S T   F O R M */}
          {activeStep === 0 && (
            <div className="newMarketForm_cont1">
              <div className="selectedMarketplaceName_cont">
                <h2>{newMarketplace.marketplaceName}</h2>
              </div>
              <div className="newMarketInput_cont">
                <label htmlFor="region" className="welcomeFormLabel">{t('region')}  <span className="asterisk">*</span></label>
                <Select
                  style={{ borderRadius: '10px' }}
                  className='regionSelect'
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="region"
                  value={newMarketplace.region}
                  onChange={handleChange}
                  displayEmpty
                  renderValue={(selected) => {
                    if (!selected) {
                      return <em>{t('selectRegion')}</em>;
                    }
                    const selectedRegion = regions.find(region => region.value === selected);
                    return selectedRegion ? selectedRegion.name : '';
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 250,
                        overflowY: 'auto',
                      },
                    },
                  }}
                >
                  {regions.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item.value}>{item.name}</MenuItem>
                    )
                  })}
                </Select>
              </div>

              <div className="newMarketInput_cont">
                <label htmlFor="marketplace" className="welcomeFormLabel">{t('marketplace')}  <span className="asterisk">*</span></label>
                <Select
                  style={{ borderRadius: '10px' }}
                  className='regionSelect'
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="marketplaceName"
                  value={newMarketplace.marketplaceName}
                  onChange={handleChange}
                  displayEmpty
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return <em>{t('selectMarketplace')}</em>;
                    }
                    return selected.charAt(0).toUpperCase() + selected.slice(1).toLowerCase();
                  }}
                >

                  {marketplaces.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item.value}>{item.name}</MenuItem>
                    )
                  })}
                </Select>
              </div>

              <div className="newMarketplaceBtn_cont">
                <button className='btnFill' disabled={newMarketError} onClick={handleNext}>{t('next')}</button>
              </div>

              <div className="newMarketError_cont">
                {newMarketError &&
                  <Alert severity="error" className='loginAlert' id='incorrectAlert'>Select Region and Marketplace.</Alert>
                }
              </div>
            </div>
          )}

          {/* S E C O N D   F O R M */}

          {/* A M A Z O N   F O R M */}
          {activeStep == 1 && newMarketplace.marketplaceName === "Amazon" && (
            <div className="amazonMarketForm_cont">
              <div className="selectedMarketplaceName_cont">
                <h2>{t('amazon')}</h2>
              </div>
              <div className="newMarketInput_cont">
                <label htmlFor="amazonAccountName" className="welcomeFormLabel">{t('accountName')}  <span className="asterisk">*</span></label>
                <TextField
                  className='welcomeFormInput'
                  InputLabelProps={{ style: { display: 'none' } }}
                  inputProps={{ autoComplete: 'off' }}
                  type='text'
                  name="amazonAccountName"
                  id="outlined-basic"
                  placeholder="Enter name"
                  variant="outlined"
                  value={amazonDetails.amazonAccountName}
                  onChange={handleAmazon}
                />
              </div>

              <div className="newMarketInput_cont">
                <label htmlFor="amazonSellerEmail" className="welcomeFormLabel">{t('sellerCentralEmail')}  <span className="asterisk">*</span></label>
                <TextField
                  className='welcomeFormInput'
                  InputLabelProps={{ style: { display: 'none' } }}
                  inputProps={{ autoComplete: 'off' }}
                  type='email'
                  name="amazonSellerEmail"
                  id="outlined-basic"
                  placeholder="Enter emal"
                  variant="outlined"
                  value={amazonDetails.amazonSellerEmail}
                  onChange={handleAmazon}
                />
              </div>

              <div className="newMarketplaceBtn_cont">
                <button className='btnFill' onClick={getAmazonURL}>{t('authenticate')}</button>
                {/* <Link className='btnFill' to='/home/amazon-auth-success?spapi_oauth_code=dfhdlfdfjdjf&selling_partner_id=28273237' onClick={handleAmazonAuth}>{t('authenticate')}</Link> */}
                {amazonLoader && <div className="loader"></div>}
                <Link className='marketBackBtn' onClick={handleBack}><ChevronLeftIcon />{t('goBack')} </Link>
              </div>

              <div className="newMarketError_cont">
                {amazonError &&
                  <Alert severity="error" className='loginAlert' id='incorrectAlert'>Please enter Amazon Account Name and Seller Email.</Alert>
                }
              </div>

            </div>
          )}

          {/* E B A Y   F O R M */}
          {activeStep == 1 && newMarketplace.marketplaceName === "eBay" && (
            <div className="amazonMarketForm_cont">
              <div className="selectedMarketplaceName_cont">
                <h2>{t('ebay')}</h2>
              </div>
              <div className="newMarketInput_cont">
                <label htmlFor="ebayAccountName" className="welcomeFormLabel">{t('accountName')}  <span className="asterisk">*</span></label>
                <TextField
                  className='welcomeFormInput'
                  InputLabelProps={{ style: { display: 'none' } }}
                  inputProps={{ autoComplete: 'off' }}
                  type='text'
                  name="ebayAccountName"
                  id="outlined-basic"
                  placeholder="Enter name"
                  variant="outlined"
                  value={ebayDetails.ebayAccountName}
                  onChange={handleEbay}
                />
              </div>

              <div className="newMarketInput_cont">
                <label htmlFor="ebaySellerEmail" className="welcomeFormLabel">{t('sellerCentralEmail')}  <span className="asterisk">*</span></label>
                <TextField
                  className='welcomeFormInput'
                  InputLabelProps={{ style: { display: 'none' } }}
                  inputProps={{ autoComplete: 'off' }}
                  type='email'
                  name="ebaySellerEmail"
                  id="outlined-basic"
                  placeholder="Enter emal"
                  variant="outlined"
                  value={ebayDetails.ebaySellerEmail}
                  onChange={handleEbay}
                />
              </div>

              <div className="newMarketplaceBtn_cont">
                <button className='btnFill' onClick={getEbayURL}>{t('authenticate')}</button>
                {/* <Link className='btnFill' to='/home/ebay-auth-success?spapi_oauth_code=arunchauhanarunchauhan&selling_partner_id=313233343536' onClick={handleEbayAuth}>{t('authenticate')}</Link> */}
                {ebayLoader && <div className="loader"></div>}
                <Link className='marketBackBtn' onClick={handleBack}><ChevronLeftIcon />{t('goBack')}</Link>
              </div>

              <div className="newMarketError_cont">
                {ebayError &&
                  <Alert severity="error" className='loginAlert' id='incorrectAlert'>Please enter Ebay Account Name and Seller Email.</Alert>
                }
              </div>
            </div>
          )}

          {/* S H O P I F Y   F O R M */}
          {activeStep == 1 && newMarketplace.marketplaceName === "Shopify" && (
            <div className="amazonMarketForm_cont">
              <div className="selectedMarketplaceName_cont">
                <h2>{t('shopify')}</h2>
              </div>
              <div className="newMarketInput_cont">
                <label htmlFor="accountName" className="welcomeFormLabel">{t('accountName')}  <span className="asterisk">*</span></label>
                <TextField
                  className='welcomeFormInput'
                  InputLabelProps={{ style: { display: 'none' } }}
                  inputProps={{ autoComplete: 'off' }} // Disable autocomplete
                  type='text'
                  name="shopifyAccountName"
                  id="outlined-basic"
                  placeholder="Enter name"
                  variant="outlined"
                  value={shopifyDetails.shopifyAccountName}
                  onChange={handleShopify}
                />
              </div>

              <div className="newMarketInput_cont">
                <label htmlFor="sellerEmail" className="welcomeFormLabel">{t('sellerCentralEmail')}  <span className="asterisk">*</span></label>
                <TextField
                  className='welcomeFormInput'
                  InputLabelProps={{ style: { display: 'none' } }}
                  inputProps={{ autoComplete: 'off' }}
                  type='email'
                  name="shopifySellerEmail"
                  id="outlined-basic"
                  placeholder="Enter email"
                  variant="outlined"
                  value={shopifyDetails.shopifySellerEmail}
                  onChange={handleShopify}
                />
              </div>

              <div className="newMarketInput_cont">
                <label htmlFor="storeName" className="welcomeFormLabel">{t('storeName')}  <span className="asterisk">*</span></label>
                <TextField
                  className='welcomeFormInput'
                  InputLabelProps={{ style: { display: 'none' } }}
                  inputProps={{ autoComplete: 'off' }}
                  type='text'
                  name="shopifyStoreName"
                  id="outlined-basic"
                  placeholder="Enter store name"
                  variant="outlined"
                  value={shopifyDetails.shopifyStoreName}
                  onChange={handleShopify}
                />
              </div>

              <div className="newMarketplaceBtn_cont">
                <button className='btnFill' onClick={handleShopifyAuthenticate}>{t('authenticate')}</button>
                <Link className='marketBackBtn' onClick={handleBack}><ChevronLeftIcon />{t('goBack')}</Link>
              </div>

              <div className="newMarketError_cont">
                {/* {mandatoryError2 && (
                  <p style={{ color: 'red', fontSize: "12px", }}>{mandatoryError2}.</p>
                )} */}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default NewMarketplace;