import React from 'react';
import './Unauthorized.css';
import UnauthorizedSvg from '../../assets/unauthorizedSvg.svg'
import { useNavigate } from 'react-router-dom';


const Unauthorized = () => {

  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };
  return (
    <div className='unAuthMain_cont'>
      <div className="unAuthNavbar">
        <button className='btnFill' onClick={handleBack}>Back</button>
      </div>
      <div className="unAuthSub_cont">
        <img src={UnauthorizedSvg} className='unAuthImg' alt="Unauthorized" />
      </div>
      <div className="unAuthHead_cont">
        <h2>Unauthorized Page</h2>
      </div>
    </div>
  )
}

export default Unauthorized;