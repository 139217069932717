import React, { useState, useEffect } from 'react';
import './App.css';

import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import PageNotFound from './components/PageNotFound/PageNotFound';

import Profile from './components/Profile/Profile';
import MyAccount from './components/MyAccount/MyAccount';
import Settings from './components/Settings/Settings';
import Welcome from './components/Welcome/Welcome';
import Main from './components/Main/Main';
import Signup from './components/Welcome/Signup/Signup';
import Login from './components/Welcome/Login/Login';

import Apps from './components/AppsCom/Apps';
import Dashboard from './components/Dashboard/Dashboard';
import Sales from './components/Sales/Sales';
import Products from './components/Products/Products';
import Reseller from './components/Reseller/Reseller';
import Consumer from './components/Consumer/Consumer';
import Shipping from './components/Shipping/Shipping';
import Whatsapp from './components/WhatsApp/Whatsapp';
import Sms from './components/Sms/Sms';
import Integration from './components/Integration/Integration';
import Users from './components/Users/Users';

import ConnectAccount from './components/WhatsApp/ConnectAccount/ConnectAccount';
import ConnectStep1 from './components/WhatsApp/ConnectAccount/ConnectStep1/ConnectStep1';
import ConnectStep2 from './components/WhatsApp/ConnectAccount/ConnectStep2/ConnectStep2';
import AfterBroadcast from './components/WhatsApp/CreateTemplate/MessageHistory/MessageHistory';

import ImportContact from './components/Consumer/ImportContact/ImportContact';
import Segment from './components/Consumer/Segment/Segment';

import StartImport from './components/Consumer/ImportContact/StartImport/StartImport';
import SubmitContact from './components/Consumer/ImportContact/StartImport/SubmitContact/SubmitContact';

import HelpGetStart from './components/Consumer/ImportContact/HelpGetStart/HelpGetStart';
import ImportGuide from './components/Consumer/ImportContact/ImportGuide/ImportGuide';
import ContactFAQ from './components/Consumer/ImportContact/ContactFAQ/ContactFAQ';

import Revenue from './components/Sales/Revenue/Revenue';
import Profit from './components/Sales/Profit/Profit';
import BuyBox from './components/Sales/BuyBox/BuyBox';
import Orders from './components/Sales/Orders/Orders';

import Catalogs from './components/Products/Catalogs/Catalogs';
import Analytics from './components/Products/Analytics/Analytics';

import CreateBroadcast from './components/Reseller/CreateBroadcast/CreateBroadcast';
import ViewBroadcast from './components/Reseller/ViewBroadcast/ViewBroadcast';
import KnowledgeBased from './components/WhatsApp/KnowledgeBased/KnowledgeBased';
import Automation from './components/Automation/Automation';

import SearchProduct from './components/Reseller/SearchProduct/SearchProduct';
import ViewMarketplace from './components/Integration/ViewMarketplace/ViewMarketplace';
import NewMarketplace from './components/Integration/NewMarketplace/NewMarketplace';

import ProtectedRoute from './components/Toolbar/ProtectedRoute';
import AmazonAuthSuccess from './components/Integration/NewMarketplace/AmazoAuthSuccess/AmazonAuthSuccess';
import EbayAuthSuccess from './components/Integration/NewMarketplace/EbayAuthSuccess/EbayAuthSuccess';
import ShopifyAuthSuccess from './components/Integration/NewMarketplace/ShopifyAuthSuccess/ShopifyAuthSuccess';
import PaymentSuccess from './components/MyAccount/PaymentSuccess/PaymentSuccess';
import PaymentFailed from './components/MyAccount/PaymentFailed/PaymentFailed';
import Terms from './components/Terms/Terms';

import EmailBuilder from './components/EmailBuilder/EmailBuilder';

function App() {


  const router = createBrowserRouter([
    {
      path: '/',
      element: <Welcome />,
    },
    {
      path: '/signin',
      element: <Login />,
    },
    {
      path: '/signup',
      element: <Signup />,
    },
    {
      path: "/home",
      element: <Main />,
      children: [
        {
          path: '/home/dashboard',
          // element: <Dashboard />,
          element: <ProtectedRoute element={<Dashboard />} requiredPrivilege="Dashboard" />,
        },
        {
          path: '/home/sales',
          // element: <Sales />,
          element: <ProtectedRoute element={<Sales />} requiredPrivilege="Sales" />,
        },
        {
          path: '/home/products',
          // element: <Products />,
          element: <ProtectedRoute element={<Products />} requiredPrivilege="Products" />,
        },
        {
          path: '/home/reseller',
          // element: <Reseller />,
          element: <ProtectedRoute element={<Reseller />} requiredPrivilege="Reseller" />,
        },
        {
          path: '/home/contacts',
          // element: <Consumer />,
          element: <ProtectedRoute element={<Consumer />} requiredPrivilege="Contacts" />,
        },
        {
          path: '/home/shipping',
          // element: <Shipping />,
          element: <ProtectedRoute element={<Shipping />} requiredPrivilege="Shipping" />,
        },
        {
          path: '/home/whatsapp',
          // element: <Whatsapp />,
          element: <ProtectedRoute element={<Whatsapp />} requiredPrivilege="WhatsApp" />,
        },
        {
          path: '/home/sms',
          // element: <Whatsapp />,
          element: <ProtectedRoute element={<Sms />} requiredPrivilege="WhatsApp" />,
        },
        {
          path: '/home/integration',
          // element: <Integration />,
          element: <ProtectedRoute element={<Integration />} requiredPrivilege="Integration" />,
        },
        {
          path: '/home/users',
          // element: <Users />,
          element: <ProtectedRoute element={<Users />} requiredPrivilege="User Management" />,
        },

        //  R O U T E S   F O R   W H A T S A P P
        {
          path: '/home/whatsapp/message-history',
          // element: <AfterBroadcast />
          element: <ProtectedRoute element={<AfterBroadcast />} requiredPrivilege="WhatsApp" />,

        },
        {
          path: '/home/whatsapp/connect-account',
          // element: <ConnectAccount />,
          element: <ProtectedRoute element={<ConnectAccount />} requiredPrivilege="WhatsApp" />,

        },
        {
          path: '/home/whatsapp/connect-account/connect-step1',
          // element: <ConnectStep1 />,
          element: <ProtectedRoute element={<ConnectStep1 />} requiredPrivilege="WhatsApp" />,

        },
        {
          path: '/home/whatsapp/connect-account/connect-step1/connect-step2',
          // element: <ConnectStep2 />,
          element: <ProtectedRoute element={<ConnectStep2 />} requiredPrivilege="WhatsApp" />,

        },
        {
          path: '/home/whatsapp/knowledge',
          // element: <KnowledgeBased />,
          element: <ProtectedRoute element={<KnowledgeBased />} requiredPrivilege="WhatsApp" />,

        },
        {
          path: '/home/whatsapp/automations',
          // element: <KnowledgeBased />,
          element: <ProtectedRoute element={<Automation />} requiredPrivilege="WhatsApp" />,

        },

        //  R O U T E S   F O R   C O N T A C T
        {
          path: '/home/contacts/segment',
          // element: <Segment />,
          element: <ProtectedRoute element={<Segment />} requiredPrivilege="Contacts" />,

        },
        {
          path: '/home/contacts/import-contact',
          // element: <ImportContact />,
          element: <ProtectedRoute element={<ImportContact />} requiredPrivilege="Contacts" />,

        },
        {
          path: '/home/contacts/import-contact/start-import',
          // element: <StartImport />,
          element: <ProtectedRoute element={<StartImport />} requiredPrivilege="Contacts" />,

        },
        {
          path: '/home/contacts/import-contact/start-import/submit-contact',
          // element: <SubmitContact />,
          element: <ProtectedRoute element={<SubmitContact />} requiredPrivilege="Contacts" />,

        },
        {
          path: '/home/contacts/import-contact/help',
          // element: <HelpGetStart />,
          element: <ProtectedRoute element={<HelpGetStart />} requiredPrivilege="Contacts" />,

        },
        {
          path: '/home/contacts/import-contact/start-import/import-guide',
          // element: <ImportGuide />,
          element: <ProtectedRoute element={<ImportGuide />} requiredPrivilege="Contacts" />,

        },
        {
          path: '/home/contacts/import-contact/start-import/FAQs',
          // element: <ContactFAQ />,
          element: <ProtectedRoute element={<ContactFAQ />} requiredPrivilege="Contacts" />,

        },

        // R O U T E S   F O R   S A L E S
        {
          path: '/home/salesrevenue',
          // element: <Revenue />,
          element: <ProtectedRoute element={<Revenue />} requiredPrivilege="Sales" />,
        },
        {
          path: '/home/salesprofit',
          // element: <Profit />,
          element: <ProtectedRoute element={<Profit />} requiredPrivilege="Sales" />,

        },
        {
          path: '/home/salesorders',
          // element: <Orders />,
          element: <ProtectedRoute element={<Orders />} requiredPrivilege="Sales" />,

        },
        {
          path: '/home/salesbuybox',
          // element: <BuyBox />,
          element: <ProtectedRoute element={<BuyBox />} requiredPrivilege="Sales" />,

        },

        // R O U T E S   F O R   P R O D U C T S 

        {
          path: '/home/product-catalogs',
          // element: <Catalogs />,
          element: <ProtectedRoute element={<Catalogs />} requiredPrivilege="Products" />,

        },
        {
          path: '/home/price-analytics',
          // element: <Analytics />,
          element: <ProtectedRoute element={<Analytics />} requiredPrivilege="Products" />,

        },

        // R O U T E S   F O R   R E S E L L E R 

        {
          path: '/home/create-broadcast',
          // element: <CreateBroadcast />,
          element: <ProtectedRoute element={<CreateBroadcast />} requiredPrivilege="Reseller" />,

        },
        {
          path: '/home/broadcasts',
          // element: <ViewBroadcast />,
          element: <ProtectedRoute element={<ViewBroadcast />} requiredPrivilege="Reseller" />,

        },
        {
          path: '/home/search-product',
          // element: <SearchProduct />,
          element: <ProtectedRoute element={<SearchProduct />} requiredPrivilege="Reseller" />,

        },

        // R O U T E S   F O R   I N T E G R A T I O N

        {
          path: '/home/view-marketplace',
          // element: <ViewMarketplace />,
          element: <ProtectedRoute element={<ViewMarketplace />} requiredPrivilege="Integration" />,

        },
        {
          path: '/home/new-marketplace',
          // element: <NewMarketplace />,
          element: <ProtectedRoute element={<NewMarketplace />} requiredPrivilege="Integration" />,
        },
        {
          path: '/home/amazon-auth-success',
          element: <AmazonAuthSuccess />
        },
        {
          path: '/home/ebay-auth-success',
          element: <EbayAuthSuccess />
        },
        {
          path: '/home/shopify-auth-success',
          element: <ShopifyAuthSuccess />
        },

        // R O U T E S   F O R   T O O L B A R

        {
          path: '/home/profile',
          element: <Profile />,
        },
        {
          path: '/home/my-account',
          element: <MyAccount />,
        },
        {
          path: '/home/settings',
          element: <Settings />,
        },

        // R O U T E S   F O R   P A Y M E N T S

        {
          path: '/home/payment-success',
          element: <PaymentSuccess />
        },
        {
          path: '/home/payment-failed',
          element: <PaymentFailed />
        },

        // R O U T E   F O R   T E R M S 

        {
          path: '/home/terms&conditions',
          element: <Terms />
        },

        // R O U T E    F O R    E M A I L    B U I L D E R 

        // {
        //   path: '/home/email',
        //   element: <EmailBuilder />
        // },
      ],

    },

    {
      path: '*',
      element: <PageNotFound />
    },
    {
      path: '/terms&conditions',
      element: <Terms />
    },
  ]);

  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
