import React, { useEffect, useState } from 'react';
import Unauthorized from '../Unauthorized/Unauthorized';

const ProtectedRoute = ({ element, requiredPrivilege }) => {
  const [privileges, setPrivileges] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const storedPrivileges = JSON.parse(localStorage.getItem('allowedPrivileges'));
    // const storedPrivileges = JSON.parse(sessionStorage.getItem('allowedPrivileges'));
    if (storedPrivileges) {
      setPrivileges(storedPrivileges.map(priv => priv.privilegeName));
    }
    setLoading(false);
  }, []);

  const hasPrivilege = Array.isArray(privileges) && privileges.includes(requiredPrivilege);

  if (loading) {
    return null;
  }

  if (!hasPrivilege) {
    return <Unauthorized />;
  }
  return element;
};

export default ProtectedRoute;
