import React, { useEffect, useState } from 'react';
import './ViewRecipients.css'
import { useTranslation } from 'react-i18next';
import DataTable from 'react-data-table-component';
import ContactTableLoader from '../../../../Loaders/ContactTableLoader/ContactTableLoader';

const ViewRecipients = ({ fetchRecipientsId, closeModal }) => {

  const { t } = useTranslation();
  const [recipients, setRecipients] = useState([]);
  const authToken = localStorage.getItem('authToken');
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [recipientsLoader, setRecipientsLoader] = useState(true);

  const columns = [
    {
      name: t("id"),
      selector: (row, index) => index + 1,
      width: "50px"
    },
    {
      name: t("userName"),
      selector: row => row.username,
    },
    {
      name: t("email"),
      selector: row => row.email,
      width: "200px"
    },
    {
      name: t("phone"),
      selector: row => row.phone,
    },
    {
      name: t("country"),
      selector: row => row.country,
      width: "100px"
    },
    {
      name: t("city"),
      selector: row => row.city,
    },
    {
      name: t("zipCode"),
      selector: row => row.zip_code,
      width: "110px"
    },
  ];

  const sentMessagesStyles = {
    headRow: {
      style: {
        position: "sticky",
        top: 0,
        color: "black",
        width: "100%",
        // border: "1px solid green",
        fontSize: "1rem",
        lineHeight: "27px",
      },
    },
    headCells: {
      style: {
        padding: "10px",
        textAlign: "left",
        width: "100%",
      },
    },
    cells: {
      style: {
        padding: "10px",
        fontSize: "0.9rem",
        fontFamily: "Poppins, Montserrat",
        color: "black",
        lineHeight: "25px",
      },
    }
  }

  // // F U N C T I O N   T O   G E T   S E G M E N T   C O N T A C T S
  // })

  useEffect(() => {
    if (!fetchRecipientsId) return;

    const fetchRecipients = async () => {
      setRecipientsLoader(true); // Start loading indicator
      const recipients_URL = `${baseUrl}/zumi/api/getcontact/${fetchRecipientsId}`;

      try {
        const response = await fetch(recipients_URL, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${authToken}`,
            'Content-Type': 'application/json',
          }
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const result = await response.json();
        setRecipients(result);
        setRecipientsLoader(false);
      } catch (error) {
        console.error('Fetch error:', error);
        setRecipientsLoader(false);
      }
    };

    fetchRecipients();
  }, [fetchRecipientsId, authToken, baseUrl]);

  return (
    <div className='viewRecipientsMain_cont'>
      <div className="newSegmentHeader">
        <div className="templateMessage_cont">
          <span className='templateMessage'>
            {t("recipients")}
          </span>

        </div>
        <div className="closeBtn_cont" role="button" aria-label="Close" onClick={closeModal}>
          <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" className="bi bi-x crossSvg" viewBox="0 0 16 16">
            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
          </svg>
        </div>
      </div>
      <div className="viewRecipients_cont">
        <div className="viewRecipientsTable_cont">
          {recipientsLoader ? (
            <ContactTableLoader />
          ) : (
            <DataTable
              columns={columns}
              customStyles={sentMessagesStyles}
              data={recipients}
              pagination
            >
            </DataTable>
          )}
        </div>
      </div>

    </div>
  )
}

export default ViewRecipients;