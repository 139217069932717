import React from 'react';
import './Profile.css';
import UserProfile from '../../../src/assets/user-profile.jpg';

const Profile = () => {
  return (
    <div className='profileMain_cont'>
      <div className="profileSub_cont">
        <div className="profileHeader_cont">
          <div className="profilePhoto_cont">
            <div className="profilePhoto">
              <img className='userProfilePhoto' src={UserProfile} alt="" />
            </div>
          </div>
          <div className="profileName_cont">
            <div className="editProfileBtn_cont">
              <button className='btnNotFill'>Edit Profile</button>
            </div>
            <p className='profileUserName'>Hemant Babbar</p>
            <p className='profileUserDesc'>More Details about Hemant Babbar.</p>
          </div>
        </div>
        <hr className='profileHr' />
        <div className="profileData_cont">
          <div className="profileDataHeader_cont">
            <p>Personal Info</p>
          </div>
          <div className="profileDataSub_cont">
            <div className="profileData1">
              <p className='profileDataParaHeader'>Full Name</p>
              <p>Hemant Babbar</p>
            </div>
            <div className="profileData2">
              <p className='profileDataParaHeader'>Email</p>
              <p>hemant@xtremeonline.com.au</p>
            </div>
            <div className="profileData3">
              <p className='profileDataParaHeader'>Phone</p>
              <p>+61 43443434</p>
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}

export default Profile;