import React, { useState, useContext, useEffect } from 'react';
import './ViewMarketplace.css';
import { useTranslation } from 'react-i18next';
import Breadcrumbs from '../../Toolbar/Breadcrumbs';
import tableStyles from '../../Sales/Revenue/TableStyles';
import ContactTableLoader from '../../Loaders/ContactTableLoader/ContactTableLoader';
import DataTable from 'react-data-table-component';
import MarketplaceContext from '../../../Context/MarketplaceContext';

import DeleteIcon from '@mui/icons-material/Delete';


const ViewMarketplace = () => {

  const { t } = useTranslation();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const authToken = localStorage.getItem('authToken');
  const deleteMarket_URL = ``;
  const [deleteMarketId, setDeleteMarketId] = useState();
  // const [marketplaceLoader, setMarketplaceLoader] = useState(true);
  const { marketplaceArray } = useContext(MarketplaceContext);
  const columns = [
    {
      name: t('accountName'),
      selector: row => row.nickname,
      // width: "60px",
    },
    {
      name: t('sellerId'),
      selector: row => row.sellerId,
      // width: "170px",
    },
    {
      name: t('marketplace'),
      selector: row => row.title,
      width: "180px",
    },
    {
      name: t('status'),
      selector: row => row.status,
      width: "100px",
    },
    {
      name: t('lastUpdated'),
      selector: row => row.lastsyncdate,
      // width: "170px",
    },
    {
      name: t('action'),
      selector: row => < div className="actionsButtonsCont" >
        <div className="deleteBtn_cont" onClick={() => { deleteMarket(row.channelId) }}>
          <DeleteIcon className='deleteIcon' style={{ height: '20px', width: '20px' }}></DeleteIcon>
        </div>
      </div>,
      width: "100px",
    }
  ];

  // D E L E T E   M A R K E T
  const deleteMarket = async (id) => {
    console.log("Delete market id: ", id)
    try {
      const response = await fetch(`${deleteMarket_URL}?id=${id}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${authToken}`,
        },
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      // Update state after successful deletion
      setDeleteMarketId(id);
      // setTemplateData(templateData.filter(item => item.id !== id));
    } catch (error) {
      console.error('Error deleting item:', error);
    }
  };

  return (
    <div className='viewMarketMain_cont'>
      <Breadcrumbs />
      <div className="marketplaceTableMain_cont">
        <div className='revenueTable_cont'>
          <DataTable
            columns={columns}
            data={marketplaceArray}
            customStyles={tableStyles}
            pagination
          />
        </div>
      </div>
    </div>
  )
}

export default ViewMarketplace;