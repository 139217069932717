import React, { useState, useEffect } from 'react';
import './PaymentFailed.css';
import cross from '../../../assets/redCross.jpg'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const PaymentFailed = () => {

  const { t } = useTranslation();
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const authToken = localStorage.getItem('authToken');
  const params = new URLSearchParams(window.location.search);
  const accessCode = params.get('AccessCode');
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (authToken) {
      const fetchData = async () => {
        try {
          const response = await fetch(`${baseUrl}/zumi/api/payment/transaction-result?accessCode=${accessCode}`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${authToken}`, // Add the Authorization header
              'Content-Type': 'application/json' // Set content type if necessary
            }
          });
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const result = await response.text();
          // console.log("Final Payment Result: ", result);
        } catch (error) {
          console.log(error);
        }
      };

      fetchData();
    }
  }, [accessCode])



  useEffect(() => {
    // Simulate a successful check
    setTimeout(() => {
      setShow(true);
    }, 100); // Adjust timing as needed
  }, []);

  return (
    <div className='payFailedMain_cont'>
      <div className="paySuccess_cont">
        <div className={`paySuccessImg_cont centerElm ${show ? 'show' : ''}`}>
          <img src={cross} alt="" />
        </div>
        <p className='paySuccessPara'>{t("paymentFailed")}</p>
        <p style={{ fontSize: "15px" }}>{t("paymentFailedPara")}</p>
        <Link className='btnNotFill paySuccessBtn' to="/home/my-account">My Account</Link>
      </div>
    </div>
  )
}

export default PaymentFailed;