import React, { useState, useEffect, useContext } from 'react';
import './Orders.css';
import Breadcrumbs from '../../Toolbar/Breadcrumbs';
import tableStyles from '../Revenue/TableStyles';
import LocationSelect from '../../Dashboard/LocationSelect/LocationSelect';
import OrdersChart from './OrdersChart/OrdersChart';
import ContactTableLoader from '../../Loaders/ContactTableLoader/ContactTableLoader';
import DataTable from 'react-data-table-component';

import { useTranslation } from 'react-i18next';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextField } from '@mui/material';
import OrdersChartContext from '../../../Context/OrdersChartContext';
import SelectedMarketplaceContext from '../../../Context/SelectedMarketplaceContext';

import TimelineIcon from '@mui/icons-material/Timeline';

const Order = () => {

  const { t } = useTranslation();
  const baseUrl = process.env.REACT_APP_BASE_URL;


  const authToken = localStorage.getItem('authToken');
  const [ordersData, setOrdersData] = useState({});
  const [amazonOrdersData, setAmazonOrdersData] = useState([]);
  const [shopifyOrdersData, setShopifyOrdersData] = useState([]);
  const [ebayOrdersData, setEbayOrdersData] = useState([]);

  const [totalSalesValue, setTotalSalesValue] = useState();
  const [totalPaidSalesValue, setTotalPaidSalesValue] = useState();
  const [totalOrders, setTotalOrders] = useState();
  const [totalPaidOrders, setTotalPaidOrders] = useState();
  const [amazonLoader, setAmazonLoader] = useState(true);
  const [shopifyLoader, setShopifyLoader] = useState(true);
  const [ebayLoader, setEbayLoader] = useState(true);

  const [currentDate, setCurrentDate] = useState(null);
  const [pastDate, setPastDate] = useState(null);
  const [weekDate, setWeekDate] = useState();

  const [roughStartDate, setRoughStartDate] = useState(null);
  const [roughEndDate, setRoughEndDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [combinedDate, setCombinedDate] = useState(null);

  const { ordersChartArray, setOrdersChartArray } = useContext(OrdersChartContext);
  const { selectedMarketplace } = useContext(SelectedMarketplaceContext);


  const columns = [
    {
      name: t("marketplace"),
      selector: row => row.title,
      width: "120px",
    },
    {
      name: t("sku"),
      selector: row => row.sku,
      width: "170px",
    },
    {
      name: t("asin"),
      selector: row => {
        if (row.asin) {
          return row.asin
        }
        else {
          return row.sku
        }
      },
      width: "170px",
    },
    {
      name: t("productInfo"),
      selector: row => row.itemName,
      width: "250px",

    },
    {
      name: t("receivedFBA"),
      selector: row => row.receivedFBA,
      width: "120px",

    },
    {
      name: t("receivedMFN"),
      selector: row => row.receivedMFN,
      width: "120px",

    },

    {
      name: t("paidFBA"),
      selector: row => row.paidFBA,
      width: "120px",

    },
    {
      name: t("paidMFN"),
      selector: row => row.paidMFN,
      width: "120px",

    },
    {
      name: t("shippedFBA"),
      selector: row => row.shippedFBA,
      width: "120px",

    },
    {
      name: t("shippedMFN"),
      selector: row => row.shippedMFN,
      width: "120px",

    },
    {
      name: t("cancelledFBA"),
      selector: row => row.cancelledFBA,
      width: "120px",

    },
    {
      name: t("cancelledMFN"),
      selector: row => row.cancelledMFN,
      width: "120px",

    },
    {
      name: t("orderDate"),
      selector: row => row.purchaseDate,
      width: "120px",

    },

  ];

  const handleStartChange = (date) => {
    setRoughStartDate(date);
  }
  const handleEndChange = (date) => {
    setRoughEndDate(date);
  }

  // F U N C T I O N   T O   G E T   C U R R E N T   D A T E

  const getCurrentDate = () => {
    const today = new Date();
    today.setDate(today.getDate() - 1);

    // Extract individual components
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const day = today.getDate();
    const month = monthNames[today.getMonth()];
    const year = today.getFullYear();

    // Construct the date string without commas
    return `${month} ${day} ${year}`;
  };

  useEffect(() => {
    setCurrentDate(getCurrentDate());
  }, []);


  // F U N C T I O N   T O   G E T  D A T E   O F   P A S T   7   D A Y S

  const getDateSevenDaysAgo = () => {
    const today = new Date();
    today.setDate(today.getDate() - 7);

    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const day = today.getDate();
    const month = monthNames[today.getMonth()];
    const year = today.getFullYear();

    return `${month} ${day} ${year}`;
    // return new Intl.DateTimeFormat('en-US', options).format(today);
  };

  useEffect(() => {
    setPastDate(getDateSevenDaysAgo());
  }, []);


  // S E T   W E E K   D A T E

  useEffect(() => {
    if (pastDate && currentDate) {
      setWeekDate(pastDate + " - " + currentDate);
    }
  }, [pastDate, currentDate]);


  // F U N T I O N   T O  S E T   R O U G H   D A T E   I N T O   F O R M A T T E D   D A T E

  useEffect(() => {
    if (roughStartDate) {
      const formattedStartDate = roughStartDate.format('MMM D YYYY');
      setStartDate(formattedStartDate);
    }
    if (roughEndDate) {
      const formattedEndDate = roughEndDate.format('MMM D YYYY');
      setEndDate(formattedEndDate);
    }
  }, [roughEndDate, roughStartDate]);

  //  F U N C T I O N   T O   C O M B I N E  D A T E S 
  useEffect(() => {
    if (startDate && endDate) {
      setCombinedDate(startDate + " - " + endDate)
      setWeekDate(null);
    }
  }, [startDate, endDate]);



  // F E T C H    O R D E R S    T A B L E   D A T A

  useEffect(() => {

    // WHEN NO DATE RANGE IS PASSED
    if (authToken && weekDate) {
      const ordersTable_URL = `${baseUrl}/zumi/api/sales/ordersdetails?dateRange=${weekDate}`;
      const fetchData = async () => {
        setAmazonLoader(true);
        setShopifyLoader(true);
        setEbayLoader(true);
        try {
          const response = await fetch(ordersTable_URL, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${authToken}`, // Add the Authorization header
              'Content-Type': 'application/json',
            },
          });
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const result = await response.json();
          setOrdersData(result);
        } catch (error) {
          console.log(error);
        }
      };
      fetchData();
    }


    // WHEN DATE RANGE IS PASSED

    if (authToken && combinedDate) {
      const ordersTable_URL = `${baseUrl}/zumi/api/sales/orderdetails?dateRange=${combinedDate}`;
      const fetchData = async () => {
        setAmazonLoader(true);
        setShopifyLoader(true);
        setEbayLoader(true);
        try {
          const response = await fetch(ordersTable_URL, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${authToken}`,
              'Content-Type': 'application/json',
            },
          });
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const result = await response.json();
          setOrdersData(result);
        } catch (error) {
          console.log(error);
        }
      };
      fetchData();
    }
  }, [authToken, weekDate, combinedDate, selectedMarketplace]);


  // S E T T I N G   S E P E R A T E   O R D E R S   D A T A 

  useEffect(() => {
    if (ordersData) {
      console.log("Orders Data: ", ordersData);
      setAmazonOrdersData(ordersData.amazonOrderList);
      setAmazonLoader(false);
      setShopifyOrdersData(ordersData.shopifyOrderList);
      setShopifyLoader(false);
      setEbayOrdersData(ordersData.ebayOrderList);
      setEbayLoader(false);
    }
  }, [ordersData])


  useEffect(() => {
    if (amazonOrdersData) {
      console.log("Amazon Data: ", amazonOrdersData);
    }
    if (shopifyOrdersData) {
      console.log("Shopify Data: ", shopifyOrdersData);
    }
    if (ebayOrdersData) {
      console.log("Ebay Data: ", ebayOrdersData);
    }
  }, [ordersData, amazonOrdersData, shopifyOrdersData, ebayOrdersData]);


  // F E T C H    O R D E R S    C H A R T   D A T A

  useEffect(() => {
    // WHEN NO DATE RANGE IS PASSED
    if (authToken && weekDate) {
      const ordersChart_URL = `${baseUrl}/zumi/api/sales/orderchart?dateRange=${weekDate}`;
      const fetchData = async () => {
        try {
          const response = await fetch(ordersChart_URL, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${authToken}`, // Add the Authorization header
              'Content-Type': 'application/json',
            },
          });
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const result = await response.json();
          setOrdersChartArray(result);
        } catch (error) {
          console.log(error);
        }
      };
      fetchData();
    }


    //   // WHEN DATE RANGE IS PASSED
    if (authToken && combinedDate) {
      const ordersChart_URL = `${baseUrl}/zumi/api/sales/revenuchart?dateRange=${combinedDate}`;

      const fetchData = async () => {
        try {
          const response = await fetch(ordersChart_URL, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${authToken}`, // Add the Authorization header
              'Content-Type': 'application/json',
            },
          });
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const result = await response.json();
          setOrdersChartArray(result);
        } catch (error) {
          console.log(error);
        }
      };
      fetchData();
    }
  }, [authToken, weekDate, combinedDate, selectedMarketplace]);


  // // T O T A L   S A L E S   V A L U E

  useEffect(() => {
    if (ordersChartArray) {
      // Calculate the sum of all counts
      const sum = ordersChartArray.reduce((acc, item) => acc + (item.totalSalesValue || 0), 0);
      const roundedSum = parseFloat(sum.toFixed(3));
      setTotalSalesValue(roundedSum);
    }
  }, [ordersChartArray, weekDate, combinedDate]);


  // // T O T A L  P A I D   S A L E S   V A L U E S 

  useEffect(() => {
    if (ordersChartArray) {
      // Calculate the sum of all counts
      const sum = ordersChartArray.reduce((acc, item) => acc + (item.totalPaidSalesValue || 0), 0);
      const roundedSum = parseFloat(sum.toFixed(3));

      setTotalPaidSalesValue(roundedSum);
    }
  }, [ordersChartArray, weekDate, combinedDate]);


  // // T O T A L  O R D E R S 

  useEffect(() => {
    if (ordersChartArray) {
      // Calculate the sum of all counts
      const sum = ordersChartArray.reduce((acc, item) => acc + (item.totalOrders || 0), 0);
      const roundedSum = parseFloat(sum.toFixed(3));

      setTotalOrders(roundedSum);
    }
  }, [ordersChartArray, weekDate, combinedDate]);


  // // T O T A L  P A I D   O R D E R S

  useEffect(() => {
    if (ordersChartArray) {
      // Calculate the sum of all counts
      const sum = ordersChartArray.reduce((acc, item) => acc + (item.totalPaidOrders || 0), 0);
      // const roundedSum = parseFloat(sum.toFixed(3));

      setTotalPaidOrders(sum);
    }
  }, [ordersChartArray, weekDate, combinedDate]);

  useEffect(() => {
    if (ordersData) {
      console.log("ordersL ", ordersData);
    }
  }, [ordersData])

  return (
    <div className='revenue_main'>
      <Breadcrumbs />
      <div className="locationMain_cont">
        <LocationSelect />
        <div className="dateRangePickerMain_cont" style={{ display: 'flex', gap: '16px' }}>
          <LocalizationProvider dateAdapter={AdapterDayjs} >
            <DatePicker
              label="Start Date"
              value={roughStartDate}
              onChange={handleStartChange}
              maxDate={dayjs()}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="End Date"
              disabled={!roughStartDate}
              value={roughEndDate}
              onChange={handleEndChange}
              maxDate={dayjs()}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </div>
      </div>


      <div className="revenueMainChartMain_cont">
        <div className="revenueMainChart_cont">
          <div className="ordersChartHeadMain_cont">
            <div className="ordersChartMainHead_cont dcont_heading">
              <span>{t("salesOrdersChartHead")}</span>
            </div>
            <div className="ordersChartTotalMain_cont">
              <div className="OrderTotalMain1 dcont_heading">
                <div className="orderTotalCont1">
                  <h5>
                    {t("totalSalesValue")}: <span className="firstrowdata" style={{ marginRight: "20px" }}>{totalSalesValue}</span>
                  </h5>

                </div>
                <div className="orderTotalCont2">
                  <h5>
                    {t("totalPaidSalesValue")}: <span className="firstrowdata" style={{ marginRight: "20px" }}>{totalPaidSalesValue} {""}</span>
                  </h5>
                </div>
              </div>
              <div className="OrderTotalMain1 dcont_heading">
                <div className="orderTotalCont1">
                  <h5>
                    {t("totalOrders")}: <span className="firstrowdata" style={{ marginRight: "20px" }}>{totalOrders} {""}</span>
                  </h5>
                </div>
                <div className="orderTotalCont2">
                  <h5>
                    {t("totalPaidOrders")}: <span className="firstrowdata" style={{ marginRight: "20px" }}>{totalPaidOrders} {""}</span>
                  </h5>
                </div>
              </div>

            </div>
          </div>
          <div className="revenueMainChart">
            <OrdersChart />
          </div>
          <div className="revenueLegendMain_cont">
            <div className="revenueLegendSub_cont">
              <div className="legend1_cont">
                <div className="legend1">

                </div>
                <div className="legendName">
                  <p>
                    {t("ordersLegend1")}
                  </p>
                </div>
              </div>
              <div className="legend1_cont">
                <div className="legend3">
                </div>
                <div className="legendName">
                  <p>
                    {t("ordersLegend3")}
                  </p>
                </div>
              </div>
              <div className="legend2_cont">
                <div className="legend2">
                  <TimelineIcon style={{ height: "2rem", width: "2rem", color: "rgb(14, 113, 195)" }} />
                </div>
                <div className="legendName">
                  <p>
                    {t("ordersLegend2")}
                  </p>
                </div>
              </div>
              <div className="legend2_cont">
                <div className="legend4">
                  <TimelineIcon style={{ height: "2rem", width: "2rem", color: "#FA7070" }} />
                </div>
                <div className="legendName">
                  <p>
                    {t("ordersLegend4")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="revenueTableMain_cont">
        <div className='revenueTable_cont'>
          {amazonLoader ? (
            <ContactTableLoader />
          ) : (
            <DataTable
              columns={columns}
              data={amazonOrdersData}
              customStyles={tableStyles}
              pagination
            />
          )}
        </div>
      </div> */}

      <div className="revenueTableMain_cont">
        <div className='revenueTable_cont'>
          {amazonLoader ? (
            <ContactTableLoader />
          ) : ebayLoader ? (
            <ContactTableLoader />
          ) : shopifyLoader ? (
            <ContactTableLoader />
          ) : amazonOrdersData ? (
            <DataTable
              columns={columns}
              data={amazonOrdersData}
              customStyles={tableStyles}
              pagination
            />
          ) : ebayOrdersData ? (
            <DataTable
              columns={columns}
              data={ebayOrdersData}
              customStyles={tableStyles}
              pagination
            />
          ) : shopifyOrdersData ? (
            <DataTable
              columns={columns}
              data={shopifyOrdersData}
              customStyles={tableStyles}
              pagination
            />
          ) : (
            <div>{t('noData')}</div>
          )}
        </div>
      </div>

    </div>
  )
}

export default Order;