import React, { useState, useContext, useEffect, useRef } from 'react';
import './Main.css';
import SideBar from '../SideBar/SideBar';
import Toolbar from '../Toolbar/Toolbar';
import { Outlet, useNavigate } from 'react-router-dom';
import MarketplaceContext from '../../Context/MarketplaceContext';
import { BrandLogoContext } from '../../Context/BrandLogoContext';
import { ThemeContext } from '../../Context/ThemeContext';
import { useTranslation } from 'react-i18next';
import { CreditsContext } from '../../Context/CreditsContext';
import { SubscriptionPlanContext } from '../../Context/SubscriptionPlanContext';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ForumIcon from '@mui/icons-material/Forum';

const Main = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const logo_url = process.env.REACT_APP_LOGO_URL;

  const authToken = localStorage.getItem('authToken');
  const userId = localStorage.getItem('userId');
  const brand_logo = localStorage.getItem('brandLogo');
  const marketplace_URL = `${baseUrl}/zumi/api/marketplaces`;
  const brandLogo_URL = `${logo_url}/static/logo/${brand_logo}`;
  // const brandLogo_URL = `http://localhost:3000/static/logo/${brand_logo}`;
  const creditBalance_URL = `${baseUrl}/zumi/api/credits/available/${userId}`;
  const subscriptionPlan_URL = `${baseUrl}/zumi/api/subscriptions/${userId}`;
  const { setMarketplaceArray } = useContext(MarketplaceContext);
  const { setBrandLogo } = useContext(BrandLogoContext);
  const { setCredits } = useContext(CreditsContext);
  const { subscriptionPlan, setSubscriptionPlan } = useContext(SubscriptionPlanContext);
  const { theme, toggleTheme } = useContext(ThemeContext);
  const inactivityTimeoutRef = useRef(null);
  const [isChatContainerVisible, setIsChatContainerVisible] = useState(false);

  // Toggle the visibility of the chat container
  const showChatContainer = () => setIsChatContainerVisible(true);

  // Close the chat container
  const hideChatContainer = () => setIsChatContainerVisible(false);


  // ----------------------------------------------------------------------------------------------

  const [userMessage, setUserMessage] = useState('');
  const [botResponse, setBotResponse] = useState('');
  const [loading, setLoading] = useState(false);

  const API_URL = 'https://api.eva.bot/v1';  // Replace with actual Eva bot API URL
  const API_KEY = '3981fb52-2967-42a8-b866-1c440cd948bd';  // Replace with your actual API key

  // Function to send message to Eva bot
  const sendMessageToEva = async (message) => {
    try {
      // Send POST request to the Eva bot API
      const response = await fetch(`${API_URL}/chat`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${API_KEY}`,  // Authentication using the API key
        },
        body: JSON.stringify({ message }),  // Send the message in the request body
      });

      // Check if the response was successful
      if (!response.ok) {
        throw new Error('Failed to communicate with Eva bot');
      }

      // Parse the JSON response
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error sending message to Eva:', error);
      throw error;
    }
  };

  // Function to handle sending a message
  const handleMessageSend = async () => {
    if (!userMessage.trim()) return; // Don't send empty messages

    setLoading(true); // Set loading state to true while waiting for response
    try {
      // Call the API function to get response from Eva bot
      const response = await sendMessageToEva(userMessage);
      setBotResponse(response.reply);  // Assuming the bot response is in the 'reply' field
    } catch (error) {
      setBotResponse('Sorry, there was an error. Please try again later.');
    } finally {
      setLoading(false); // Set loading state back to false once response is received
    }
  };

  // ----------------------------------------------------------------------------------------------

  // F E T C H   M A R K E T P L A C E S
  useEffect(() => {
    fetch(marketplace_URL, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${authToken}`,
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setMarketplaceArray(data);
      })
      .catch(error => {
        console.error('There was a problem with the fetch operation:', error);
      });
  }, [authToken]);


  // F E T C H    B R A N D   L O G O
  useEffect(() => {
    fetch(brandLogo_URL, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${authToken}`,
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response;
      })
      .then(data => {
        setBrandLogo(data.url);
      })
      .catch(error => {
        console.error('There was a problem with the fetch operation:', error);
      });
  }, [authToken]);


  // F E T C H   C R E D I T   B A L A N C E
  useEffect(() => {
    fetch(creditBalance_URL, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${authToken}`,
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        // setCreditBalanceData(data);
        setCredits(data)
      })
      .catch(error => {
        console.error('There was a problem with the fetch operation:', error);
      });
  }, [authToken]);


  // F E T C H   S U B S C R I P T I O N   P L A N
  useEffect(() => {
    fetch(subscriptionPlan_URL, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${authToken}`,
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        // setCreditBalanceData(data);
        setSubscriptionPlan(data)
      })
      .catch(error => {
        console.error('There was a problem with the fetch operation:', error);
      });
  }, [authToken]);


  // U S E R    L O G O U T    A U T O M A T I C A L L Y
  useEffect(() => {
    let timer;

    const resetTimer = () => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        localStorage.clear();
        navigate('/'); // Navigate to home page
      }, 15 * 60 * 1000); // 15 minutes
    };

    // Set the initial timer
    resetTimer();

    // Event listeners for user activity
    const events = ['click', 'keydown', 'mousemove', 'scroll'];
    events.forEach(event => {
      window.addEventListener(event, resetTimer);
    });

    // Cleanup function
    return () => {
      clearTimeout(timer);
      events.forEach(event => {
        window.removeEventListener(event, resetTimer);
      });
    };
  }, [navigate]);


  // R E T U R N   I F   N O T   A U T H T O K E N
  if (!authToken) {
    return null;

  }


  return (
    <div id='main_cont' className={`${theme}`}>
      <div className="MaintoolbarCont">
        <Toolbar />
      </div>
      <div className="MainMainCont">
        <div className="MainSideBarCont">
          <SideBar />
        </div>
        <div className="MainContentCont">
          <Outlet />
        </div>

        <div className="speed-dial-button centerElm" onClick={showChatContainer}>
          {/* <ForumIcon style={{ height: 30, width: 30 }} /> */}
          <ForumIcon />
        </div>

        <div className={`chat-container ${isChatContainerVisible ? 'visible' : ''}`}>
          <button className="closeChatBtn centerElm" onClick={hideChatContainer}>
            <KeyboardArrowDownIcon />
          </button>
          <h3>Chat Container</h3>
          <p>Chat container...</p>


          {/* -------------------------------------------------------------------- */}

          <h2>Eva Bot</h2>
          <div style={{ marginBottom: '10px', padding: '10px', background: '#f1f1f1', borderRadius: '5px' }}>
            <strong>Bot:</strong> {botResponse || 'Ask me anything!'}
          </div>

          <div style={{ marginBottom: '10px' }}>
            <input
              type="text"
              value={userMessage}
              onChange={(e) => setUserMessage(e.target.value)}
              placeholder="Type a message..."
              style={{ width: '100%', padding: '8px', fontSize: '16px', borderRadius: '5px' }}
            />
          </div>

          <button
            onClick={handleMessageSend}
            disabled={loading}
            style={{
              width: '100%',
              padding: '10px',
              backgroundColor: '#007BFF',
              color: '#fff',
              border: 'none',
              borderRadius: '5px',
              fontSize: '16px',
            }}
          >
            {loading ? 'Sending...' : 'Send'}
          </button>

          {/* -------------------------------------------------------------------- */}

        </div>
      </div>
    </div>
  );
};

export default Main;
