import React, { useContext, useState, useEffect } from 'react'
import './Consumer.css';
import Breadcrumbs from '../Toolbar/Breadcrumbs';
import tableStyles from '../Sales/Revenue/TableStyles';
import LocationSelect from '../Dashboard/LocationSelect/LocationSelect';
import DataTable from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ContactsContext from '../../Context/ContactsContext';
import ContactTableLoader from '../Loaders/ContactTableLoader/ContactTableLoader';

import CellTowerIcon from '@mui/icons-material/CellTower';
import HistoryIcon from '@mui/icons-material/History';
import LinkIcon from '@mui/icons-material/Link';
import QuizIcon from '@mui/icons-material/Quiz';

import TextField from '@mui/material/TextField';




const Consumer = () => {

  const { t } = useTranslation();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const authToken = localStorage.getItem('authToken');

  const { contactsArray, setContactsArray } = useContext(ContactsContext);
  const contact_URL = `${baseUrl}/zumi/api/getcontact`;

  const [contactData, setContactData] = useState([]);
  const [contactLoader, setContactLoader] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');


  const columns = [
    {
      name: t('contactId'),
      selector: row => row.consumerId,
      width: "100px",
    },
    {
      name: t("name"),
      selector: row => row.firstName

    },
    {
      name: t('email'),
      selector: row => row.email

    },
    {
      name: t('marketplace'),
      selector: row => row.marketplaceName
    },
    {
      name: t('phone'),
      selector: row => row.phone
    },
    {
      name: t('city'),
      selector: row => row.city
    },
    {
      name: t('country'),
      selector: row => row.country,
      width: "90px"
    },
    {
      name: t('zipCode'),
      selector: row => row.zipCode,
      width: "90px"
    }
  ];



  // F E T C H   C O N T A C T S   D A T A 

  useEffect(() => {
    if (authToken) {
      const fetchData = async () => {
        try {
          const response = await fetch(contact_URL, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${authToken}`, // Add the Authorization header
              'Content-Type': 'application/json' // Set content type if necessary
            }
          });
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const result = await response.json();
          setContactData(result);
        } catch (error) {
          console.log(error);
        } finally {
          setContactLoader(false);
        }
      };
      fetchData();
    }

  }, [authToken]);

  // S E T T I N G   C O N T A C T S   C O N T E X T
  useEffect(() => {
    if (contactData) {
      setContactsArray(contactData);
    }
  }, [contactData, setContactsArray]);


  // S O R T   C O N T A C T S    D A T A 

  // const handleSegmentSort = (column, sortSegmentDirection) => {
  //   setSortSegmentDirection(sortSegmentDirection);
  // };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };


  // S O R T   A N D   S E A R C H   C O N T A C T S    D A T A 
  const filteredContactData = React.useMemo(() => {
    const filteredData = contactData.filter(item =>
      typeof item.firstName === 'string' &&
      item.firstName.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return filteredData;
  }, [contactData, searchQuery]);


  return (
    <div className='consumer_main'>
      {/* <div className="whatToolMain_cont">
        <div className="whatToolsBtns_cont">
          <Link
            className='whatToolsLink'
            to="/home/whatsapp"
          >
            <CellTowerIcon style={{ marginRight: "8px" }} /> Broadcast
          </Link>
          <Link
            className='whatToolsLink'
            to="/home/whatsapp/message-history"
          >
            <HistoryIcon style={{ marginRight: "8px" }} /> Message History
          </Link>
          <Link
            className='whatToolsLink'
            to="/home/whatsapp/connect-account"
          >
            <LinkIcon style={{ marginRight: "8px" }} /> Connect Account
          </Link>
          <Link
            className='whatToolsLink'
            to="/home/whatsapp/knowledge"
          >
            <QuizIcon style={{ marginRight: "8px" }} /> Knowledge Base
          </Link>
        </div>
      </div> */}
      <div className='searchAndbtn_cont'>
        <div className="searchTemplate_row">
          <div className="searchSortTemplate_cont">
            <TextField
              className='searchTemplateInput'
              id="outlined-size-small"
              size="small"
              placeholder='Search contacts'
              onChange={handleSearch}
            />
          </div>
          <div className="templateRowBtn_cont">
            <Link to={'/home/contacts/import-contact'} className='btnFill importContactsBtn'>{t('importContacts')}</Link>
            <Link to={'/home/contacts/segment'} className="btnFill">{t('viewSegments')}</Link>
          </div>

        </div>
      </div>
      <div className="contactTableMain_cont">
        <div className='contactTable_cont'>
          {contactLoader ? (
            <ContactTableLoader />
          ) : (
            <DataTable
              columns={columns}
              // data={contactsArray}
              data={filteredContactData}
              customStyles={tableStyles}
              pagination
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default Consumer;