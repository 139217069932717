import React, { useState } from 'react';
import './TaskView.css';
import { useTranslation } from 'react-i18next';
import DataTable from 'react-data-table-component';

const TaskView = ({ closeModal, taskData }) => {

  const { t } = useTranslation();
  const authToken = localStorage.getItem('authToken');


  const [taskView, setTaskView] = useState(taskData);

  const columns = [
    // {
    //   name: t("id"),
    //   selector: (row, index) => index + 1,
    //   style: { width: '60px' },
    // },
    {
      name: t("campaignName"),
      selector: row => row.campaignName,
      width: "60px"
    },
    {
      name: t("taskName"),
      selector: row => row.taskName,
      width: "60px"
    },
    {
      name: t("user"),
      selector: row => row.user,
      width: "60px"
    },
    {
      name: t("serviceType"),
      selector: row => row.serviceType,
      width: "60px"
    },
    {
      name: t("taskStatus"),
      selector: row => row.taskStatus,
      width: "60px"
    },
    {
      name: t("senderCli"),
      selector: row => row.senderCli,
      width: "60px"
    },
    {
      name: t("startDateTime"),
      selector: row => row.startDateTime,
      width: "60px"
    },
    {
      name: t("endDateTime"),
      selector: row => row.endDateTime,
      width: "60px"
    },
    {
      name: t("uploaded"),
      selector: row => row.uploaded,
      width: "60px"
    },
    {
      name: t("failed"),
      selector: row => row.failed,
      width: "60px"
    },
    {
      name: t("submitted"),
      selector: row => row.submitted,
      width: "60px"
    },
    {
      name: t("action"),
      selector: row => row.action,
      width: "60px"
    },
  ];

  const sentMessagesStyles = {
    headRow: {
      style: {
        position: "sticky",
        top: 0,
        color: "black",
        width: "100%",
        // border: "1px solid green",
        fontSize: "1rem",
        lineHeight: "27px",
      },
    },
    headCells: {
      style: {
        padding: "10px",
        textAlign: "left",
        width: "100%",
      },
    },
    cells: {
      style: {
        padding: "10px",
        fontSize: "0.9rem",
        fontFamily: "Poppins, Montserrat",
        color: "black",
        lineHeight: "25px",
      },
    }
  }

  return (
    <div className='taskViewMain_cont'>
      <div className="newSegmentHeader">
        <div className="templateMessage_cont">
          <span className='templateMessage'>
            {t('viewTask')}
          </span>

        </div>
        <div className="closeBtn_cont" role="button" aria-label="Close" onClick={closeModal}>
          <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" className="bi bi-x crossSvg" viewBox="0 0 16 16">
            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
          </svg>
        </div>
      </div>

      <div className="viewTaskMain">

        <div className="viewTaskSearch_cont">

        </div>
        <div className="viewRecipients_cont">
          <div className="viewRecipientsTable_cont">
            <DataTable
              columns={columns}
              customStyles={sentMessagesStyles}
              data={taskView}
              pagination
            >
            </DataTable>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TaskView;