import React from 'react';
import './ImportContact.css';
import { useTranslation } from 'react-i18next';


import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import CloseIcon from '@mui/icons-material/Close';

import { Link } from 'react-router-dom';
import Breadcrumbs from '../../Toolbar/Breadcrumbs';

const ImportContact = () => {
  const { t } = useTranslation();


  const closeContactHelp = () => {
    const helpCont = document.querySelector(".importContactHelp_cont");
    helpCont.style.display = 'none';
  }


  return (
    <div className='importContactMain_cont'>
      <Breadcrumbs />
      <div className="importContactHeading_cont">
        <h3 style={{ textAlign: "center" }}>{t('importContactMainHead')}</h3>
      </div>

      <div className="importContactMain">
        <div className="importOneMain">
          <div className="importCardOne_cont">
            <div className="importImgCont">
              <img className='importContactImg' src="//static.hsappstatic.net/ui-images/static-2.677/optimized/mobile-onboarding-import-contacts.svg" alt="" />
            </div>
            <h4 className='importContactH5'>{t('import')}</h4>
            <p className='importContactPara'>{t('importCardPara')}</p>
            <div className="importContactBtn_cont">
              <Link to="/home/contacts/import-contact/start-import" className='btnFill'>{t('import')}</Link>
            </div>
          </div>
        </div>


        <div className="importTwoMain">
          <div className="importCardTwo_cont">
            <div className="importImgCont">
              <img className='importContactImg' src="//static.hsappstatic.net/ui-images/static-2.677/optimized/api.svg" alt="" />
            </div>
            <h4 className='importContactH5'>{t('sync')}</h4>
            <p className='importContactPara'>{t('syncCardpara')}</p>
            <div className="importContactBtn_cont">
              <Link to="/home/contacts/import-contact/sync" className='btnFill'>{t('sync')}</Link>
            </div>

          </div>
        </div>

      </div>
      <div className="importContactHelp_cont">
        <div className="helpIcon_cont">
          <HelpCenterIcon />

        </div>
        <div className="helpContent_cont">
          <h4>{t('importHelpHead')}</h4>
          <p className='helpPara'>{t('importHelpPara')}<Link to="/home/contacts/import-contact/help" className='' style={{ color: "rgb(14, 113, 195)", marginLeft: "5px" }}>{t('importHelpLink')}</Link></p>
        </div>
        <div className="helpCross_cont">
          <CloseIcon className='crossSvg' onClick={closeContactHelp} />
        </div>
      </div>
    </div>
  )
}

export default ImportContact;