import React from 'react';
import './PageNotFound.css';
import PageNotFoundSvg from '../../assets/pageNotFoundSvg.svg'
import { useNavigate } from 'react-router-dom';

const PageNotFound = () => {

  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };
  return (
    <div className='notFoundMain_cont'>
      <div className="notfoundNavbar">
        <button className='btnFill' onClick={handleBack}>Back</button>
      </div>
      <div className="notFoundSub_cont">
        <img src={PageNotFoundSvg} className='notFoundImg' alt="Page Not Found" />
      </div>
      <div className="notFoundHead_cont">
        <h2>Page Not Found</h2>
      </div>
    </div>
  )
}
export default PageNotFound;