import React, { useState } from 'react';
import './NewKeyword.css';
import { useTranslation } from 'react-i18next';
import { TextField } from '@mui/material';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';


const NewKeyword = ({ replyMaterial, closeModal }) => {
  const { t } = useTranslation();
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const [keywordAction, setKeywordAction] = useState({
    keywordName: "",
    matchingMethod: "",
    replyMessage: ""
  });


  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setKeywordAction(prevState => ({ ...prevState, [name]: value }));
  }

  const handleSave = () => {
    console.log("Keyword Action: ", keywordAction);
  }


  const isSaveDisabled = !keywordAction.keywordName || !keywordAction.matchingMethod || !keywordAction.replyMessage;

  return (
    <div>
      <div className="newRoleHeader">
        <div className="templateMessage_cont">
          <span className='templateMessage'>
            {/* {t('newKeywordAction')} */}
          </span>
        </div>
        <div className="closeBtn_cont" role="button" aria-label="Close" onClick={closeModal}>
          <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" className="bi bi-x crossSvg" viewBox="0 0 16 16">
            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
          </svg>
        </div>
      </div>

      <div className="newKeySubCont">
        <div className="newTextFormInput_cont">
          <label htmlFor="materialName" className="welcomeFormLabel">{t('addkeyword')}  <span className="asterisk">*</span></label>
          <TextField
            className='welcomeFormInput'
            InputLabelProps={{ style: { display: 'none' } }}
            inputProps={{ autoComplete: 'off' }}
            type='text'
            name="keywordName"
            id="outlined-basic"
            placeholder="Type something..."
            variant="outlined"
            value={keywordAction.keywordName}
            onChange={handleChange}
          />
        </div>

        <div className="newTextFormInput_cont">
          <label htmlFor="materialName" className="welcomeFormLabel">{t('selectMatchingMethod')}  <span className="asterisk">*</span></label>
          <Select
            className='regionSelect'
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name="matchingMethod"
            value={keywordAction.matchingMethod}
            onChange={handleChange}
          // displayEmpty
          // renderValue={(selected) => {
          //   if (!selected) {
          //     return <em>{t('selectRole')}</em>;
          //   }
          //   const selectedRole = roles.find(item => item.roleId === selected);
          //   return selectedRole ? selectedRole.roleName : <em>{t('selectRole')}</em>; // Show the role name
          // }}
          // MenuProps={{
          //   PaperProps: {
          //     style: {
          //       maxHeight: 200, // Set your desired height
          //       overflowY: 'auto',
          //     },
          //   },
          // }}
          >

            {/* {roles.map((item, index) => {
              return (
                <MenuItem key={index} value={item.roleId}>{item.roleName}</MenuItem>
              )
            })} */}
            <MenuItem value="fuzzy">Fuzzy matching</MenuItem>
            <MenuItem value="exact">Exact matching</MenuItem>
            <MenuItem value="contain">Contains</MenuItem>

          </Select>
        </div>

        <div className="newTextFormInput_cont">
          <label htmlFor="materialName" className="welcomeFormLabel">{t('selectReplyMessage')}  <span className="asterisk">*</span></label>
          <Select
            className='regionSelect'
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name="replyMessage"
            value={keywordAction.replyMessage}
            onChange={handleChange}
          // displayEmpty
          // renderValue={(selected) => {
          //   if (!selected) {
          //     return <em>{t('selectRole')}</em>;
          //   }
          //   const selectedRole = roles.find(item => item.roleId === selected);
          //   return selectedRole ? selectedRole.roleName : <em>{t('selectRole')}</em>; // Show the role name
          // }}
          // MenuProps={{
          //   PaperProps: {
          //     style: {
          //       maxHeight: 200, // Set your desired height
          //       overflowY: 'auto',
          //     },
          //   },
          // }}
          >
            {replyMaterial.map((item, index) => {
              return (
                <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
              )
            })}

          </Select>
        </div>

        <div className="keywordSaveBtn_cont">
          <button
            className='btnFill'
            onClick={handleSave}
            disabled={isSaveDisabled}
            style={{
              border: isSaveDisabled ? "1px solid #E9EED9" : "#17c1e8",
              backgroundColor: isSaveDisabled ? '#E9EED9' : '#17c1e8',
              color: isSaveDisabled ? 'gray' : '#fff',
            }}
          >
            {t('save')}
          </button>
        </div>

      </div>
    </div>
  )
}

export default NewKeyword;