import React, { useRef, useEffect, useState } from 'react';
import './WelcomeMainContent.css';

import { Link, } from 'react-router-dom';
import { TextField } from '@mui/material';
import heroSvg from '../../../assets/heroSvg.svg';
import connectSvg from '../../../assets/connectSvg.svg'
// import client1Img from '../../../assets/client-1.png';
// import client2Img from '../../../assets/client-2.png';
import amazonImg from '../../../assets/amazon.png';
import eBayImg from '../../../assets/eBay.png';
import shopify from '../../../assets/shopify.png';
import consumerImg from '../../../assets/consumerImg.png';

// import heroSvg from '../../../assets/heroSvg.svg'

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';




const textFieldStyle = {
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      // borderColor: 'rgb(23, 193, 232)',
      borderRadius: "10px",
      // borderWidth: "1.5px",
    },
    '&:hover fieldset': {
      // borderColor: 'black',
      borderRadius: "10px",
    },
    '&.Mui-focused fieldset': {
      // borderColor: 'black',
      borderRadius: "10px",
    },
  },
}

// A N I M A T I O N   F O R  H E R O  H E A D I N G   C O N T A I N E R 


const WelcomeMainContent = () => {

  const [contactInfo, setContactInfo] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  })

  // H A N D L E   C H A N G E 
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setContactInfo({ ...contactInfo, [name]: value })
  }

  // H A N D L E   S E N D
  const handleSend = (e) => {
    e.preventDefault();
    console.log(contactInfo);
  }

  //  A N I M A T I O N   F O R   H E R O   S V G   C O N T A I N E R
  const heroSubCont1Ref = useRef(null);
  useEffect(() => {
    if (heroSubCont1Ref.current) {
      heroSubCont1Ref.current.classList.add('animate');
    }
  }, []);


  // A N I M A T I O N   F O R   H E R O   S V G   C O N T A I N E R 
  const heroSubCont2Ref = useRef(null);
  useEffect(() => {
    if (heroSubCont2Ref.current) {
      heroSubCont2Ref.current.classList.add('animate');
    }
  }, []);


  return (
    <div className='welcomeMainContentMain_cont'>

      {/* W E L C O M E   H E R O */}
      <div className="welcomeHeroMain_cont" id='hero'>
        <div className="hero_cont1">
          <div className="heroSub_cont1" ref={heroSubCont1Ref}>
            <h1 className='heroBigHead'>Empowering Resellers,</h1>
            <h1 className='heroBigHead'>Elevating Marketplaces</h1>
            <h2 className='herosubHead'>Answering Fundamental Questions For Sellers</h2>
            <h3 className='heroSmallHead'>What to Sell?  Profit I made?  Who is Buying?</h3>
            <div className="heroBtn_cont">
              <Link className='btnFill welcomeBtn' to="/signup">Get Started</Link>
            </div>
            {/* <div className="heroMarket_cont">
              <div className="heroMarket"><h2>AMAZON</h2></div>
              <div className="heroMarket"><h2>EBAY</h2></div>
              <div className="heroMarket"><h2>SHOPIFY</h2></div>
            </div> */}
          </div>
        </div>
        <div className="hero_cont2">
          <div className="heroSub_cont2" ref={heroSubCont2Ref}>
            <img src={heroSvg} alt="" />
          </div>
        </div>
      </div>

      {/* W E L C O M E   C O N N E C T */}
      <div className="welcomeConnectMain_cont" id='connect'>
        <div className="sectionHead_cont">
          <h2 className='sectionHeading'>360° Connect</h2>
        </div>

        <div className="welcomeConnectSub_cont">

          {/* SVG Container for mobile */}

          <div className="connect_cont3">
            <div className="connectSub_cont2">
              <img src={connectSvg} alt="" />
            </div>
          </div>

          <div className="connect_cont1">
            <div className="connectSub_cont1">
              <h3 className='connectBigHead'>Connect easily with your marketplace, suppliers, and customers using ZUMI, our all-in-one platform.</h3>
              <br />
              <p className='connectPara'>
                <b>Marketplaces:</b> Link up with top global marketplaces and get
                your business data in an easy-to-understand format.
              </p>

              <p className='connectPara'>
                <b>Suppliers/Wholesalers:</b> Get instant price quotes from
                suppliers/wholesalers, match them with your listings, and see your
                Return on Investment (RoI) before selling.
              </p>

              <p className='connectPara'>
                <b>Customers:</b> Sync customer data from all your stores, run
                targeted campaigns for sales and service, and collect feedback
                effortlessly
              </p>

              <div className="welcomeConnectSubHead_cont">
                <div className="connectSubHead">
                  <h3 >ZUMI makes connecting with your business world simple and straightforward.</h3>
                </div>
                <div className="">
                  <div>
                    <Link className="btnNotFill welcomeBtn" to="/signup" style={{ marginTop: "20px" }}>Get Started</Link>
                  </div>
                </div>
              </div>
            </div>

          </div>

          {/* SVG Container for laptop */}

          <div className="connect_cont2">
            <div className="connectSub_cont2">
              <img src={connectSvg} alt="" />
            </div>
          </div>
        </div>
      </div>

      {/* W E L C O M E  M A R K E T P L A C E */}
      <div className="welcomeMarketplaceMain_cont" id='marketplace'>
        <div className="sectionHead_cont">
          <h2 className='sectionHeading'>Marketplace</h2>
        </div>

        <div className="marketplace_cont">
          <div className="marketplace">
            <img src={amazonImg} alt="amazon" />
          </div>
          <div className="marketplace">
            <img src={eBayImg} alt="eBay" />
          </div>
          <div className="marketplace">
            <img src={shopify} alt="shoify" />
          </div>
        </div>
        <div className="marketplaceSub_cont">
          <div className="marketplace_cont1">
            <div className="marketplaceSub_cont1">
              <h3 className='connectBigHead'>Revolutionise Your Reselling Game with ZUMI: Your
                All-in-One Marketplace Solution</h3>
              <br />
              <p className='connectPara'>ZUMI, your gateway to unparalleled efficiency, seamlessly
                integrates with eCommerce giants – Amazon, eBay, and Shopify. It
                aggregates near realtime data from all three sources through APIs
              </p>
              <p className='connectPara'>
                <b>Unified API Integration -</b> Get data from all three
                marketplaces in one dashboard
              </p>
              <p className='connectPara'>
                <b>Real-Time Insights -</b> ZUMI synchronizes sales and orders
                data across marketplaces
              </p>
              <p className='connectPara'>
                <b>Intelligent Price Analytics -</b> With ZUMI's analytics,
                monitor competitor prices, secure the Buybox, and optimize
                product position
              </p>
            </div>
          </div>
          <div className="marketplace_cont2">
            <div className="marketplaceSub_cont2">
              <p className='connectPara'>
                <b>Precise Cost and Profit Calculations -</b> Manage costs and
                profits with ZUMI, clearly understand your Return on Investment
                (RoI).
              </p>
              <p className='connectPara'>
                <b>Product Rank Insights -</b> Make data-driven decisions with
                ZUMI's insights into product ranks and optimise product
                portfolio.
              </p>
              <p className='connectPara'>
                <b>Connect with Consumers directly -</b> ZUMI stores consumer
                data from marketplaces in its CRM system and provides insights
                like customer spend, past purchases and preferred
                brands/products.
              </p>
              <p className='connectPara'>
                <b>Marketing with ZUMI -</b> Send intelligent marketing campaigns
                to your customers from multiple marketplaces. send email, SMS or
                WhatsApp messages.
              </p>
              <div className="welcomeConnectSubHead_cont">
                <div className="marketplaceSubHead">
                  <h3>Embark on the future of reselling – experience ZUMI.</h3>
                </div>
                <div className="">
                  <div>
                    <Link className="btnNotFill welcomeBtn" to="/signup" style={{ marginTop: "20px" }}>Get Started</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* W E L C O M E   W H O L E S A L E  */}
      <div className="welcomeWholesaleMain_cont" id='wholesale'>
        <div className="sectionHead_cont">
          <h2 className='sectionHeading'>Wholesale</h2>
        </div>
        <div className="welcomeWholesaleSub_cont">
          <div className="wholesale_cont1">
            <div className="wholesaleSub_cont1">
              <h3 className='connectBigHead'>Transform Your Trade Dynamics with ZUMI: A Seamless Hub
                for Suppliers/Wholesalers and Sellers!
              </h3>
              <br />
              <p className='connectPara'>
                <b>Global Connectivity, Local Impact -</b> ZUMI seamlessly
                connects Suppliers with Sellers worldwide. From dropping shipping
                to product buying, ZUMI supports all business scenarios .
              </p>
              <p className='connectPara'>
                <b>Informed Decision-Making - </b> Suppliers/Wholesalers share
                real-time quotations on ZUMI, allowing Sellers to make informed
                decisions with searchable quotes based on item content.
              </p>
            </div>
          </div>
          <div className="wholesale_cont2">
            <div className="wholesaleSub_cont2">
              <p className='connectPara'>
                <b>Organised Records, Quick Access -</b> Bid farewell to the
                chaos of lost quotes in messaging channels. ZUMI maintains a
                meticulous record of all quotations, ensuring easy search-ability
                and quick access when needed.
              </p>
              <p className='connectPara'>
                <b>Efficient Communication -</b> Suppliers can broadcast quotes
                via email and ensuring efficient communication beyond
                conventional chat platforms.
              </p>
              <p className='connectPara'>
                <b>Historical Insights for Strategy -</b> Sellers can access
                historical data effortlessly. ZUMI enables strategic
                decision-making by allowing Sellers to compare past prices
                against the latest marketplace rates.
              </p>
              <div className="welcomeConnectSubHead_cont">
                <div className="wholesaleSubHead">
                  <h3>Redefining global trade—where connectivity meets commerce</h3>
                </div>
                <div className="">
                  <div>
                    <Link className="btnNotFill welcomeBtn" to="/signup" style={{ marginTop: "20px" }}>Get Started</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* W E L C O M E  C O N S U M E R S  */}
      <div className="welcomeConsumersMain_cont" id='consumer'>
        <div className="sectionHead_cont">
          <h2 className='sectionHeading'>Consumers</h2>
        </div>
        <div className="welcomeConsumersSub_cont">
          <div className="consumer_cont3">
            <div className="consumerSub_cont2">
              <img src={consumerImg} className='consumer_chart' alt="consumer_chart" />
            </div>
          </div>
          <div className="consumer_cont1">
            <div className="consumerSub_cont1">
              <h3 className='connectBigHead'>Elevate Your Customer Connect with ZUMI: A Fusion of
                Data-Driven Strategies and Personalised Connections
              </h3>
              <br />
              <p className='connectPara'>ZUMI consolidates data seamlessly from various shopfronts,
                including marketplaces. It powers an advanced CRM system driven
                by consumer purchase history and AI-driven segmentation.
              </p>
              <div className="consumerList_cont" style={{ paddingLeft: "30px" }}>
                <ul>
                  <li className='consumerPara'><b>Direct Seller-Customer Connection -</b> ZUMI empowers
                    direct connections between sellers and customers, fostering
                    meaningful relationships that transcend marketplace limitations</li>
                  <li className='consumerPara'><b>Competitive Pricing Strategies -</b> ZUMI enables
                    competitive pricing strategies across platforms/shopfronts.</li>
                  <li className='consumerPara'><b>Direct Customer Support -</b> ZUMI allows sellers to
                    provide customer support directly. Helping them manage reviews
                    and ratings better leading to customer retention and
                    satisfaction.</li>
                  <li className='consumerPara'><b>Brand/Seller Loyalty and Trust -</b> Utilize consumer
                    data to fortify brand loyalty and trust, establish enduring
                    connections with your audience.</li>
                  <li className='consumerPara'><b>Personalised Marketing -</b> ZUMI's AI-driven
                    segmentation enables sellers to send targeted, personalised
                    recommendations.</li>
                  <li className='consumerPara'><b>Exclusive Products -</b> Showcase exclusive products
                    through ZUMI.</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="consumer_cont2">
            <div className="consumerSub_cont2">
              <img src={consumerImg} className='consumer_chart' alt="consumer_chart" />
            </div>
          </div>
        </div>
      </div>

      {/* W E L C O M E   P R I C I N G */}
      <div className="welcomePricingMain_cont" id='pricing'>
        <div className="sectionHead_cont">
          <h2 className='sectionHeading'>Pricing</h2>
        </div>
        <div className="welcomePriceingSub_cont">
          <div className="welcomePricingSubHead_cont">
            <h3 className='connectBigHead' style={{ color: "rgb(52, 71, 103)", textAlign: "center" }}>
              You can establish your account now and choose a subscription plan at a later time. Experience all the premium features of ZUMI at no cost.
            </h3>
          </div>
          <div className="pricingCardMain_cont">
            <div className="pricingCard_cont">
              <div className="pricingCardName_cont">
                <h3 className='priceCardName'>Freemium</h3>

              </div>
              <div className="pricingCardSub_cont">
                <h4 className='price'>
                  <sup>$</sup>49
                  <span className='perMonthSpan'>per month</span>
                </h4>
                <ul className='pricingFeatureList'>
                  <li><CheckIcon style={{ color: "#06D001" }} />Amazon Integration</li>
                  <li><CheckIcon style={{ color: "#06D001" }} />Ebay Integration</li>
                  <li><CheckIcon style={{ color: "#06D001" }} />Shopify Integration</li>
                  <li><CheckIcon style={{ color: "#06D001" }} />Profitability Dashboard</li>
                  <li><CheckIcon style={{ color: "#06D001" }} />Supplier Connect</li>
                  <li><CheckIcon style={{ color: "#06D001" }} />Supplier Quote Analytics</li>
                  <li><CheckIcon style={{ color: "#06D001" }} />90 day event tracking</li>
                  <li><CheckIcon style={{ color: "#06D001" }} />24*7 Phone support</li>
                  <li><CheckIcon style={{ color: "#06D001" }} />Customer Data in CRM</li>

                  <li><CloseIcon style={{ color: "red" }} />Competitor tracking</li>
                  <li><CloseIcon style={{ color: "red" }} />Dedicated Account Manager</li>
                </ul>
              </div>
              <div className="priceBtn_cont">
                <Link href="signup" className="btnFill priceBtn welcomeBtn" to="/signup">Get Started</Link>
              </div>
            </div>
            <div className="pricingCard_cont">
              <div className="pricingCardName_cont">
                <h3 className='priceCardName'>Starter</h3>
              </div>
              <div className="pricingCardSub_cont">
                <h4 className='price'>
                  <sup>$</sup>99
                  <span className='perMonthSpan'>per month per marketplace</span>
                </h4>
                <ul className='pricingFeatureList'>
                  <li><CheckIcon style={{ color: "#06D001" }} />Amazon Integration</li>
                  <li><CheckIcon style={{ color: "#06D001" }} />Ebay Integration</li>
                  <li><CheckIcon style={{ color: "#06D001" }} />Shopify Integration</li>
                  <li><CheckIcon style={{ color: "#06D001" }} />Profitability Dashboard</li>
                  <li><CheckIcon style={{ color: "#06D001" }} />Supplier Connect</li>
                  <li><CheckIcon style={{ color: "#06D001" }} />Supplier Quote Analytics</li>
                  <li><CheckIcon style={{ color: "#06D001" }} />90 day event tracking</li>
                  <li><CheckIcon style={{ color: "#06D001" }} />24*7 Phone support</li>
                  <li><CheckIcon style={{ color: "#06D001" }} />Customer Data in CRM</li>

                  <li><CloseIcon style={{ color: "red" }} />Competitor tracking</li>
                  <li><CloseIcon style={{ color: "red" }} />Dedicated Account Manager</li>
                </ul>
              </div>
              <div className="priceBtn_cont">
                <Link href="signup" className="btnFill priceBtn welcomeBtn" to="/signup">Get Started</Link>
              </div>
            </div>
            <div className="pricingCard_cont">
              <div className="pricingCardName_cont">
                <h3 className='priceCardName'>Professional</h3>

              </div>
              <div className="pricingCardSub_cont">
                <h4 className='price'>
                  <sup>$</sup>299
                  <span className='perMonthSpan'>per month per marketplace</span>
                </h4>
                <ul className='pricingFeatureList'>
                  <li><CheckIcon style={{ color: "#06D001" }} />Amazon Integration</li>
                  <li><CheckIcon style={{ color: "#06D001" }} />Ebay Integration</li>
                  <li><CheckIcon style={{ color: "#06D001" }} />Shopify Integration</li>
                  <li><CheckIcon style={{ color: "#06D001" }} />Profitability Dashboard</li>
                  <li><CheckIcon style={{ color: "#06D001" }} />Supplier Connect</li>
                  <li><CheckIcon style={{ color: "#06D001" }} />Supplier Quote Analytics</li>
                  <li><CheckIcon style={{ color: "#06D001" }} />365 day event tracking</li>
                  <li><CheckIcon style={{ color: "#06D001" }} />24*7 Phone support</li>
                  <li><CheckIcon style={{ color: "#06D001" }} />Customer Data in CRM</li>

                  <li><CheckIcon style={{ color: "#06D001" }} />Competitor tracking</li>
                  <li><CheckIcon style={{ color: "#06D001" }} />Dedicated Account Manager</li>
                </ul>
              </div>
              <div className="priceBtn_cont">
                <Link href="signup" className="btnFill priceBtn welcomeBtn" to="/signup">Get Started</Link>
              </div>
            </div>

          </div>

        </div>
      </div>

      {/* W E L C O M E   C O N T A C T */}
      <div className="welcomeContactMain_cont" id='contact'>
        <div className="sectionHead_cont">
          <h2 className='sectionHeading'>Contact</h2>
        </div>
        <div className="welcomeContactSub_cont">
          <div className="contact_cont1">
            <div className="contactSub_cont1">
              <div className="contactLinksMain_cont">
                <div className="contactLinks_cont">
                  <div className="contactLinkSvg_cont">
                    <MailOutlineIcon style={{ height: "1.4em", width: "1.4em" }} />
                  </div>
                  <div className="contactLink_cont">
                    <h4 className='contactLinkHeading'>Sales Queries:</h4>
                    <p>
                      <a className='welcomeContactLink' href="mailto: hemant@xtremeonline.com.au">hemant@xtremeonline.com.au</a>
                    </p>
                  </div>
                </div>
                <div className="contactLinks_cont">
                  <div className="contactLinkSvg_cont">
                    <MailOutlineIcon style={{ height: "1.4em", width: "1.4em" }} />
                  </div>
                  <div className="contactLink_cont">
                    <h4 className='contactLinkHeading'>Product Queries:</h4>
                    <p>
                      <a className='welcomeContactLink' href="mailto: hemant@xtremeonline.com.au">hemant@xtremeonline.com.au</a>
                    </p>
                  </div>
                </div>
                <div className="contactLinks_cont">
                  <div className="contactLinkSvg_cont">
                    <PhoneIphoneIcon style={{ height: "1.4em", width: "1.4em" }} />
                  </div>
                  <div className="contactLink_cont">
                    <h4 className='contactLinkHeading' >Call:</h4>
                    <p className='welcomeContactLink'>+1300 978 258</p>
                  </div>
                </div>
              </div>
              <div className="contactMap_cont">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3314.7426507755467!2d151.0020753750716!3d-33.818953716477715!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b12a3f0168d2ded%3A0x2ec0d01d9b60330b!2sLevel%204%2F106%20Church%20St%2C%20Parramatta%20NSW%202150%2C%20Australia!5e0!3m2!1sen!2sin!4v1724065974154!5m2!1sen!2sin" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
              </div>
            </div>
          </div>
          <div className="contact_cont2">
            <div className="contactSub_cont2">
              <form>
                <div className="formInput_cont">
                  <label htmlFor="name" className="welcomeFormLabel">Name  <span className="asterisk">*</span></label>
                  <TextField
                    className='welcomeFormInput'
                    InputLabelProps={{ style: { display: 'none' } }}
                    name="name"
                    id="outlined-basic"
                    placeholder="Enter your name"
                    variant="outlined"
                    sx={textFieldStyle}
                    value={contactInfo.name}
                    onChange={handleChange}

                  />

                </div>
                <div className="formInput_cont">
                  <label htmlFor="email" className="welcomeFormLabel">Email  <span className="asterisk">*</span></label>
                  <TextField
                    className='welcomeFormInput'
                    InputLabelProps={{ style: { display: 'none' } }}
                    name="email"
                    id="outlined-basic"
                    placeholder="Enter email"
                    variant="outlined"
                    sx={textFieldStyle}
                    value={contactInfo.email}
                    onChange={handleChange}

                  />

                </div>
                <div className="formInput_cont">
                  <label htmlFor="subject" className="welcomeFormLabel">Subject  <span className="asterisk">*</span></label>
                  <TextField
                    className='welcomeFormInput'
                    InputLabelProps={{ style: { display: 'none' } }}
                    name="subject"
                    id="outlined-basic"
                    placeholder="Enter Subject"
                    variant="outlined"
                    sx={textFieldStyle}
                    value={contactInfo.subject}
                    onChange={handleChange}

                  />
                </div>
                <div className="formInput_cont">
                  <label htmlFor="message" className="welcomeFormLabel">Message <span className="asterisk">*</span></label>

                  <TextField
                    className='welcomeFormInput'
                    multiline
                    rows={4}
                    InputLabelProps={{ style: { display: 'none' } }}
                    name="message"
                    id="outlined-basic"
                    placeholder="Enter message..."
                    variant="outlined"
                    sx={textFieldStyle}
                    value={contactInfo.message}
                    onChange={handleChange}

                  />

                </div>
                <div className="formInput_cont">
                  <button className='btnFill' onClick={handleSend}>Send</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WelcomeMainContent;