// ThemeContext.js
import React, { createContext, useState } from 'react';

export const SubscriptionPlanContext = createContext();

export const SubscriptionPlanProvider = ({ children }) => {
  const [subscriptionPlan, setSubscriptionPlan] = useState(0);

  return (
    <SubscriptionPlanContext.Provider value={{ subscriptionPlan, setSubscriptionPlan }}>
      {children}
    </SubscriptionPlanContext.Provider>
  );
};
