import React, { useState } from "react";
import AllowedPrivilegeContext from "./AllowedPrivilegesContext";
const AllowedPrivilegesContextProvider = ({ children }) => {

  const [allowedPrivileges, setAllowedPrivileges] = useState([]);

  return (
    <AllowedPrivilegeContext.Provider value={{ allowedPrivileges, setAllowedPrivileges }}>
      {children}
    </AllowedPrivilegeContext.Provider>
  )

}

export default AllowedPrivilegesContextProvider;