import React, { useState, useEffect } from 'react';
import './ScheduleTask.css';
import { useTranslation } from 'react-i18next';
import { TextField } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Link } from 'react-router-dom';

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import Checkbox from '@mui/material/Checkbox';



const ScheduleTask = ({ closeModal }) => {
  const { t } = useTranslation();
  const authToken = localStorage.getItem('authToken');
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };


  const [selectedFile, setSelectedFile] = useState(null);
  const [fileError, setFileError] = useState('');

  const [scheduleTaskData, setScheduleTaskData] = useState({
    startDateTime: "",
    endDateTime: "",
    cli: "",
    taskName: "",
    mode: "",
    smsc: "",
    shortenUrl: "",
  })

  const [roughStartDateTime, setRoughStartDateTime] = useState(null);
  const [roughEndDateTime, setRoughEndDateTime] = useState(null);
  const [startDateTime, setStartDateTime] = useState(null);
  const [endDateTime, setEndDateTime] = useState(null);

  const [shortenUrl, setShortenUrl] = useState(false);

  const handleStartDateTimeChange = (date) => {
    setRoughStartDateTime(date)
  }

  const handleEndDateTimeChange = (date) => {
    setRoughEndDateTime(date);
  }

  const handleShortenUrl = () => {
    setShortenUrl(!shortenUrl);
  }

  // H A N D L I N G   F I L E   S E L E C T I O N 
  const selectedSmsFileHandler = (event) => {
    const file = event.target.files[0]
    // setSelectedFile(file);

    if (file) {
      const fileName = file.name;
      const fileExtension = fileName.split('.').pop().toLowerCase(); // Get file extension

      // Check if the file extension is valid
      if (['csv', 'xlsx', 'xls'].includes(fileExtension)) {
        setFileError(''); // Clear error message
        setSelectedFile(file);
        // Proceed with processing the file
      } else {
        setFileError(t("invalidFileError"));
        event.target.value = ''; // Clear the input
      }
    }
  }

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setScheduleTaskData(prevState => ({ ...prevState, [name]: value }));
  }

  // F U N T I O N   T O  S E T   R O U G H   D A T E T I M E   I N T O   F O R M A T T E D   D A T E T I M E
  useEffect(() => {
    if (roughStartDateTime) {
      const formattedStartDateTime = roughStartDateTime.format('D MMMM YYYY h:mm a');
      // console.log('Formatted Start Date:', formattedStartDate);
      setStartDateTime(formattedStartDateTime);
    }
    if (roughEndDateTime) {
      const formattedEndDateTime = roughEndDateTime.format('D MMMM YYYY h:mm a');
      // console.log('Formatted End Date:', formattedEndDate);
      setEndDateTime(formattedEndDateTime);
    }
  }, [roughEndDateTime, roughStartDateTime]);

  useEffect(() => {
    setScheduleTaskData(prevState => ({ ...prevState, startDateTime: startDateTime, endDateTime: endDateTime }));
  }, [startDateTime, endDateTime])

  useEffect(() => {
    setScheduleTaskData(prevState => ({ ...prevState, shortenUrl: shortenUrl }));
  }, [shortenUrl])


  const handleSave = () => {
    console.log("Schedule task data: ", scheduleTaskData);

    const formData = new FormData();
    // Append JSON data to FormData
    for (const [key, value] of Object.entries(scheduleTaskData)) {
      formData.append(key, value);
    }

    // Append the selected file
    if (selectedFile) {
      formData.append('contactFile', selectedFile);
    }

    // Log FormData for testing
    for (let [key, value] of formData.entries()) {
      if (value instanceof File) {
        console.log(key, value.name); // Display file name instead of file object
      } else {
        console.log(key, value); // Display value of other fields
      }
    }
  }

  const isSaveDisabld = !scheduleTaskData.startDateTime || !scheduleTaskData.endDateTime || !scheduleTaskData.cli || !scheduleTaskData.taskName || !scheduleTaskData.mode || !scheduleTaskData.smsc;

  return (
    <div className='scheduleTaskMain_cont'>
      <div className="newSegmentHeader">
        <div className="templateMessage_cont">
          <span className='templateMessage'>
            {t('scheduleTask')}
          </span>

        </div>
        <div className="closeBtn_cont" role="button" aria-label="Close" onClick={closeModal}>
          <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" className="bi bi-x crossSvg" viewBox="0 0 16 16">
            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
          </svg>
        </div>
      </div>

      <div className="scheduleTaskMain">

        {/* R O W  1 */}
        <div className="createSmsRow">
          <div className="createSmsInput_cont " style={{ marginRight: "10px" }}>
            <label htmlFor="startDateTime" className="payLabel">{t('startDateTime')}  <span className="asterisk">*</span></label>
            <div className="dateRangePickerMain_cont">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DateTimePicker']}>
                  <DateTimePicker
                    name="startDateTime"
                    minDate={dayjs()} // Disable past dates
                    value={roughStartDateTime}
                    onChange={handleStartDateTimeChange}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </div>
          </div>
          <div className="createSmsInput_cont">
            <label htmlFor="endDateTime" className="payLabel">{t('endDateTime')}  <span className="asterisk">*</span></label>
            <div className="dateRangePickerMain_cont">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DateTimePicker']}>
                  <DateTimePicker
                    name="endDateTime"
                    minDate={dayjs()} // Disable past dates
                    value={roughEndDateTime}
                    onChange={handleEndDateTimeChange}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </div>
          </div>
        </div>

        {/* R O W  2 */}
        <div className="createSmsRow">
          <div className="createSmsInput_cont" style={{ marginRight: "10px" }}>
            <label htmlFor="cli" className="payLabel">{t('cli')}  <span className="asterisk">*</span></label>
            <Select
              className='filterSelect'
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="cli"
              value={scheduleTaskData.cli}
              onChange={handleChange}
              displayEmpty
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return <em>Select CLI</em>;
                }
                return selected.charAt(0).toUpperCase() + selected.slice(1).toLowerCase();
              }}
            >
              <MenuItem value="1919">1919</MenuItem>
              <MenuItem value="2525">2525</MenuItem>
            </Select>
          </div>
          <div className="createSmsInput_cont">
            <label htmlFor="smsFile" className="payLabel">{t('uploadFile')}  <span className="asterisk">*</span></label>
            <div className="demoInputMUI">
              <input
                type='file'
                accept=".csv"
                className='uploadFileBtn smsInputField'
                name="smsContactFile"
                id="uploadBtn"
                onChange={selectedSmsFileHandler}
              />
              <label htmlFor="uploadBtn" className='uploadSmsFileLabel'>
                {/* <ImageIcon style={{ marginRight: "5px" }} /> */}
                {t('selectImgBtn')}
              </label>
              <div className="fileName_cont" id="fileName_cont">
                {selectedFile ? (
                  <p><span className='fileName'>{selectedFile.name}</span> </p>
                ) : <p className='nofile'>{t("noFileSelected")}</p>}
              </div>
            </div>

          </div>
        </div>
        {/* R O W  3 */}
        <div className="createSmsRow">
          <div className="createSmsInput_cont" style={{ marginRight: "10px" }}>
            <label htmlFor="taskName" className="payLabel">{t('taskName')}  <span className="asterisk">*</span></label>
            <TextField
              className='smsInputField'
              InputLabelProps={{ style: { display: 'none' } }}
              variant="outlined"
              name="taskName"
              id="outlined-basic"
              placeholder="Enter a task name"
              value={scheduleTaskData.taskName}
              onChange={handleChange}
            />
          </div>
          <div className="createSmsInput_cont">
            <label htmlFor="contacts" className="payLabel">{t('modeType')}  <span className="asterisk">*</span></label>
            <Select
              className='filterSelect'
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="mode"
              value={scheduleTaskData.mode}
              onChange={handleChange}
              displayEmpty
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return <em>Select a mode type</em>;
                }
                return selected.charAt(0).toUpperCase() + selected.slice(1).toLowerCase();
              }}
            >
              <MenuItem value="Promotional">Promotional</MenuItem>
              <MenuItem value="Transactional">Transational</MenuItem>
            </Select>
          </div>
        </div>

        {/* R O W   4 */}
        <div className="createSmsRow">
          <div className="createSmsInput_cont" style={{ marginRight: "10px" }}>
            <label htmlFor="smsc" className="payLabel">{t('smsc')}  <span className="asterisk">*</span></label>
            <Select
              className='filterSelect'
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="smsc"
              value={scheduleTaskData.smsc}
              onChange={handleChange}
              displayEmpty
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return <em>Select SMSC</em>;
                }
                return selected.charAt(0).toUpperCase() + selected.slice(1).toLowerCase();
              }}
            >
              <MenuItem value="HTTP">HTTP</MenuItem>
              <MenuItem value="SMPP">SMPP</MenuItem>
            </Select>
          </div>
          <div className="createSmsInput_cont" style={{ display: "flex", alignItems: "center" }}>
            {/* Empty Container */}
            <div className="shortenUrl_cont">
              <Checkbox {...label} onClick={handleShortenUrl} /> Please select checkbox for shorten URL if you want
            </div>
          </div>
        </div>



        <div className="scheduleTaskBtn_cont">
          <button
            className='btnFill'
            onClick={handleSave}
            disabled={isSaveDisabld}
            style={{
              border: isSaveDisabld ? "1px solid #E9EED9" : "#17c1e8",
              backgroundColor: isSaveDisabld ? '#E9EED9' : '#17c1e8',
              color: isSaveDisabld ? 'gray' : '#fff',
            }}
          >{t('save')}
          </button>
        </div>

      </div>
    </div>
  )
}

export default ScheduleTask;