import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './i18next/i18n';
import { ThemeProvider } from './Context/ThemeContext';
import MarketplaceContextProvider from './Context/MarketplaceContextProvider';
import SelectedMarketplaceContextProvider from './Context/SelectedMarketplaceContextProvider';
import ContactsContextProvider from './Context/ContactsContextProvider';
import SegmentContextProvider from './Context/SegmentContextProvider';
import TemplateContextProvider from './Context/TemplateContextProvider';
import TokenContextProvider from './Context/TokenContextProvider';
import TotalSalesContextProvider from './Context/TotalSalesContextProvider';
import UnitsSoldContextProvider from './Context/UnitsSoldContextProvider';
import ProfitContextProvider from './Context/ProfitContextProvider';
import RevenueContextProvider from './Context/RevenueContextProvider';
import ProfitChartContextProvider from './Context/ProfitChartContextProvider';
import OrdersChartContextProvider from './Context/OrdersChartContextProvider';
import BuyBoxChartContextProvider from './Context/BuyboxChartContextProvider';
import AllowedPrivilegesContextProvider from './Context/AllowedPrivilegesContextProvier';
import { CurrencyProvider } from './Context/CurrencyContext';
import { CreditsProvider } from './Context/CreditsContext';
import { BrandLogoProvider } from './Context/BrandLogoContext';
import { SubscriptionPlanProvider } from './Context/SubscriptionPlanContext';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrandLogoProvider>
      <ThemeProvider>
        <SubscriptionPlanProvider>
          <CreditsProvider>
            <CurrencyProvider>
              <AllowedPrivilegesContextProvider>
                <MarketplaceContextProvider>
                  <SelectedMarketplaceContextProvider>
                    <ContactsContextProvider>
                      <SegmentContextProvider>
                        <TemplateContextProvider>
                          <TokenContextProvider>
                            <TotalSalesContextProvider>
                              <UnitsSoldContextProvider>
                                <ProfitContextProvider>
                                  <RevenueContextProvider>
                                    <ProfitChartContextProvider>
                                      <OrdersChartContextProvider>
                                        <BuyBoxChartContextProvider>
                                          <App />
                                        </BuyBoxChartContextProvider>
                                      </OrdersChartContextProvider>
                                    </ProfitChartContextProvider>
                                  </RevenueContextProvider>
                                </ProfitContextProvider>
                              </UnitsSoldContextProvider>
                            </TotalSalesContextProvider>
                          </TokenContextProvider>
                        </TemplateContextProvider>
                      </SegmentContextProvider>
                    </ContactsContextProvider>
                  </SelectedMarketplaceContextProvider>
                </MarketplaceContextProvider>
              </AllowedPrivilegesContextProvider>
            </CurrencyProvider>
          </CreditsProvider>
        </SubscriptionPlanProvider>
      </ThemeProvider>
    </BrandLogoProvider>
  </React.StrictMode>
);

reportWebVitals();
