import React, { useState, useEffect, useContext } from 'react';
import './CreateBroadcast.css';
import Breadcrumbs from '../../Toolbar/Breadcrumbs';
import { TextField } from '@mui/material';

import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Link } from 'react-router-dom';
import DownloadIcon from '@mui/icons-material/Download';
// import Alert from '@mui/material/Alert';
import { useTranslation } from 'react-i18next';
import SegmentContext from '../../../Context/SegmentContext';

import Autocomplete from '@mui/material/Autocomplete';

import Alert from '@mui/material/Alert';


const CreateBroadcast = () => {
  const { t } = useTranslation();
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const authToken = localStorage.getItem("authToken");
  const createBroadcast_URL = `${baseUrl}/zumi/api/createbroadcost`
  const [selectedQuoteFile, setSelectedQuoteFile] = useState(null);
  const [selectedContactFile, setSelectedContactFile] = useState(null);

  const [successAlert, setSuccessAlert] = useState(false);
  const [failedAlert, setFailedAlert] = useState(false);

  const segment_URL = `${baseUrl}/zumi/api/getsegment`;
  const [segmentData, setSegmentData] = useState([]);
  const { segmentArray, setSegmentArray } = useContext(SegmentContext);

  const [createBroadcastData, setCreateBroadcastData] = useState({
    supplierId: 1,
    supplierName: "",
    email: "",
    phone: "",
    address: "",
    segmentName: "",
  });

  // H A N D L E   Q U O T E   F I L E 
  const handleQuoteFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedQuoteFile(file);
  };

  // H A N D L E  C O N T A C T   F I L E 
  const handleContactFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedContactFile(file);
  };

  // H A N D L E   C H A N G E   F U N C T I O N 
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setCreateBroadcastData((prevData) => ({ ...prevData, [name]: value }));
  }

  // F E T C H   S E G M E N T S   D A T A 
  useEffect(() => {
    if (authToken) {

      const fetchData = async () => {
        try {
          const response = await fetch(segment_URL, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${authToken}`, // Add the Authorization header
              'Content-Type': 'application/json' // Set content type if necessary
            }
          });
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const result = await response.json();
          setSegmentData(result);
        } catch (error) {
          console.log(error);
        }

      };
      fetchData();
    }
  }, []);

  // S E T T I N G   S E G M E N T   C O N T E X T 
  useEffect(() => {
    if (segmentData) {
      setSegmentArray(segmentData);
    }
  }, [segmentArray, segmentData]);

  // S U B M I T   F U N C T I O N 
  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent default form submission behavior

    const formData = new FormData();

    // Append form data
    for (const key in createBroadcastData) {
      formData.append(key, createBroadcastData[key]);
    }

    // Append files
    if (selectedQuoteFile) formData.append('uploadQuotation', selectedQuoteFile);
    if (selectedContactFile) formData.append('uploadContact', selectedContactFile);

    // Log FormData for testing
    for (let [key, value] of formData.entries()) {
      if (value instanceof File) {
        console.log(key, value.name); // Display file name instead of file object
      } else {
        console.log(key, value); // Display value of other fields
      }
    }

    fetch(createBroadcast_URL, {
      method: 'POST',
      body: formData,
      headers: {
        'Authorization': `Bearer ${authToken}`, // Add the Authorization header
      }
    }).then(response => {
      if (response.ok) { // Check if the response status is in the range 200-299
        setSuccessAlert(true);
      } else {
        // Handle different status codes
        let message = `Error: ${response.status} ${response.statusText}`;
        setFailedAlert(true);
        throw new Error(message); // Optionally throw an error to stop further processing
      }
    }).then(
      () => {
        setCreateBroadcastData({
          supplierId: "",
          supplierName: "",
          email: "",
          phone: "",
          address: "",
          segmentName: "",
        })

        setSelectedQuoteFile(null);
        setSelectedContactFile(null);
      }
    )

    // setCreateBroadcastData({
    //   supplierId: "",
    //   supplierName: "",
    //   email: "",
    //   phone: "",
    //   address: "",
    //   segmentName: "",
    // })

    // setSelectedQuoteFile(null);
    // setSelectedContactFile(null);
  };

  // H A N D L E   S A M P L E   Q U O T E   F I L E   D O W N L O A D
  const handleQuoteFileDownload = async () => {
    try {
      const response = await fetch(`${baseUrl}/zumi/resellerQuotationSampleFile.csv`,
        // const response = await fetch(`http://localhost:3000/resellerQuotationSampleFile.csv`,
        {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${authToken}`, // Add the Authorization header
          },
        }
      );
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'resellerQuotationSampleFile.csv');        // file name
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url); // Clean up
    } catch (error) {
      console.error('Error downloading the file', error);
    }
  };

  // H A N D L E   S A M P L E   C O N T A C T S   F I L E   D O W N L O A D
  const handleContactFileDownload = async () => {
    try {
      const response = await fetch(`${baseUrl}/zumi/resellerContactSampleFile.csv`,
        // const response = await fetch(`http://localhost:3000/resellerContactSampleFile.csv`,

        {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${authToken}`, // Add the Authorization header
          },
        }
      );
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'resellerContactSampleFile.csv');        // file name
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url); // Clean up
    } catch (error) {
      console.error('Error downloading the file', error);
    }
  };

  const isSubmitDisabled =
    !createBroadcastData.supplierId ||
    !createBroadcastData.supplierName ||
    !createBroadcastData.email ||
    !createBroadcastData.phone ||
    !createBroadcastData.address ||
    !selectedQuoteFile ||
    (!selectedContactFile && !createBroadcastData.segmentName);

  return (
    <div className='createBroadcastMain_cont'>
      <Breadcrumbs />
      <div className="createBroadcast_cont">

        <div className="createBroadcastRow">
          <div className="createBroadcastInput_cont" style={{ marginRight: "20px" }}>
            <label htmlFor="contactPerson" className="welcomeFormLabel">{t('dealerName')}  <span className="asterisk">*</span></label>
            <TextField
              className='welcomeFormInput'
              InputLabelProps={{ style: { display: 'none' } }}
              inputProps={{ autoComplete: 'off' }} // Disable autocomplete
              type='text'
              name="supplierName"
              id="outlined-basic"
              placeholder="Enter name"
              variant="outlined"
              value={createBroadcastData.supplierName}
              onChange={handleChange}
            />
          </div>

          <div className="createBroadcastInput_cont">
            <label htmlFor="contactPerson" className="welcomeFormLabel">{t('email')}  <span className="asterisk">*</span></label>
            <TextField
              className='welcomeFormInput'
              InputLabelProps={{ style: { display: 'none' } }}
              inputProps={{ autoComplete: 'off' }} // Disable autocomplete
              type='email'
              name="email"
              id="outlined-basic"
              placeholder="Enter email"
              variant="outlined"
              value={createBroadcastData.email}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="createBroadcastRow">
          <div className="createBroadcastInput_cont" style={{ marginRight: "20px" }}>
            <label htmlFor="contactPerson" className="welcomeFormLabel">{t('phone')}  <span className="asterisk">*</span></label>
            <TextField
              className='welcomeFormInput'
              InputLabelProps={{ style: { display: 'none' } }}
              inputProps={{ autoComplete: 'off' }} // Disable autocomplete
              type='number'
              name="phone"
              id="outlined-basic"
              placeholder="Enter phone"
              variant="outlined"
              value={createBroadcastData.phone}
              onChange={handleChange}
            />
          </div>

          <div className="createBroadcastInput_cont">
            <label htmlFor="contactPerson" className="welcomeFormLabel">{t('companyAddress')}  <span className="asterisk">*</span></label>
            <TextField
              className='welcomeFormInput'
              InputLabelProps={{ style: { display: 'none' } }}
              inputProps={{ autoComplete: 'off' }} // Disable autocomplete
              type='text'
              name="address"
              id="outlined-basic"
              placeholder="Enter address"
              variant="outlined"
              value={createBroadcastData.address}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="createBroadcastRow">
          <div className="createBroadcastInput_cont" style={{ marginRight: "20px" }}>
            <label htmlFor="contactPerson" className="welcomeFormLabel">{t('uploadQuote')}  <span className="asterisk">*</span></label>
            <input
              type='file'
              accept=".csv, .xlsx, .xls"
              className='uploadFileBtn'
              name="uploadQuotation"
              id="uploadQuoteBtn"
              onChange={handleQuoteFileChange}
            />
            <div className="createBroadCastFileLabel_cont">
              <label htmlFor="uploadQuoteBtn" className='uploadQuoteLabel'>
                Choose File
              </label>
              <div className="selectedQuoteFile_cont">
                {selectedQuoteFile && <p style={{ color: "green" }}>{selectedQuoteFile.name}</p>}
              </div>
            </div>
          </div>

          <div className="createBroadcastInput_cont">
            <label htmlFor="contactPerson" className="welcomeFormLabel">{t('uploadContact')}  <span className="asterisk">*</span></label>
            <div className="demoInputMUI">
              <input
                disabled={createBroadcastData.segmentName}
                type='file'
                accept=".csv, .xlsx, .xls"
                className='uploadFileBtn smsInputField'
                name="smsContactFile"
                id="uploadBtn"
                onChange={handleContactFileChange}
              />
              <label
                htmlFor="uploadBtn"
                className='uploadSmsFileLabel'
                style={{
                  border: createBroadcastData.segmentName ? "1px solid #E9EED9" : "#17c1e8",
                  backgroundColor: createBroadcastData.segmentName ? '#E9EED9' : '#17c1e8',
                  color: createBroadcastData.segmentName ? 'gray' : '#fff',
                }}
              >
                Choose File
              </label>
              <div className="fileName_cont" id="fileName_cont">
                {selectedContactFile ? (
                  <p><span className='fileName' style={{ fontSize: "12px" }}>{selectedContactFile.name}</span> </p>
                ) : <p className='nofile' style={{ fontSize: "12px" }}>{t("noFileSelected")}</p>}
              </div>
              <div className="orContainer centerElm">or</div>
              <Select
                disabled={selectedContactFile}
                className="selectSegmentSms"
                name='segmentName'
                size='small'
                value={createBroadcastData.segmentName}
                onChange={handleChange}
                displayEmpty
                renderValue={(selected) => {
                  const selectedSegment = segmentArray.find(item => item.segmentId === selected);
                  return selectedSegment ? selectedSegment.segmentName : <em>{t("selectSegmentPlaceholder")}</em>;
                }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 230,
                      overflowY: 'auto',
                    },
                  },
                }}
              >
                {
                  segmentArray.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item.segmentId}>{item.segmentName}</MenuItem>
                    )
                  })
                }
              </Select>

            </div>
          </div>
        </div>

        <div className="createBroadcastBtn_cont">
          <div className="createBroadcastHelpLinks_cont">
            <div className="helpAnchorCont">
              <Link className='helpAnchor' onClick={handleQuoteFileDownload}>
                {t('downloadQuoteFile')}
                <DownloadIcon className='launchIcon' style={{ width: "18px", height: "18px", marginLeft: "5px" }} />
              </Link>
            </div>
            <div className="helpAnchorCont" style={{ marginRight: "0px" }}>
              <Link className='helpAnchor' onClick={handleContactFileDownload}>
                {t('downloadContactFile')}
                <DownloadIcon className='launchIcon' style={{ width: "18px", height: "18px", marginLeft: "5px" }} />
              </Link>
            </div>
          </div>
          <button
            className='btnFill'
            onClick={handleSubmit}
            disabled={isSubmitDisabled}
            style={{
              border: isSubmitDisabled ? "1px solid #E9EED9" : "#17c1e8",
              backgroundColor: isSubmitDisabled ? '#E9EED9' : '#17c1e8',
              color: isSubmitDisabled ? 'gray' : '#fff',
            }}
          >
            {t('submit')}
          </button>
        </div>

        <div className="loginAlert_cont">
          {successAlert &&
            <Alert severity="success" className='loginAlert' id='incorrectAlert'>Broadcast Successfull.</Alert>
          }
          {failedAlert &&
            <Alert severity="error" className='loginAlert' id='incorrectAlert'>Broadcast Failed.</Alert>
          }
        </div>
      </div>
    </div>
  )
}

export default CreateBroadcast;