import React, { useState, useEffect, useRef } from 'react';
import './WelcomeNavbar.css';
import zumiBrandImg from '../../../assets/zumiBrandImg.png';
import { Link, useLocation } from 'react-router-dom';

import DehazeIcon from '@mui/icons-material/Dehaze';
import StoreIcon from '@mui/icons-material/Store';
import SellIcon from '@mui/icons-material/Sell';
import PeopleIcon from '@mui/icons-material/People';
import MoneyIcon from '@mui/icons-material/Money';
import FeedIcon from '@mui/icons-material/Feed';

const WelcomeNavbar = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const sidebarRef = useRef(null);
  const contentRef = useRef(null);
  const location = useLocation();

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target) && contentRef.current && !contentRef.current.contains(event.target)) {
      setIsSidebarOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    if (isSidebarOpen) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.body.classList.remove('no-scroll');
    };
  }, [isSidebarOpen]);

  const handleScroll = (e, offset) => {
    e.preventDefault();

    setIsSidebarOpen(!isSidebarOpen)
    const targetId = e.currentTarget.getAttribute('href').substring(1);
    const targetElement = document.getElementById(targetId);

    if (targetElement) {
      window.scrollTo({
        top: targetElement.offsetTop - offset,
        behavior: 'smooth'
      });
    }
  };

  //  G E T T I N G   S I G N I N   A N D   S I G N U P    P A G E
  const isSignInPage = location.pathname === '/signin';
  const isSignUpPage = location.pathname === '/signup';

  return (
    <div className="welcomeNavbarMain_cont" ref={contentRef}>
      <a href="/" className="welcomeNavbarBrand_cont">
        <img src={zumiBrandImg} alt="brandImg" style={{ height: 40, width: 80 }} />
        {/* <h1 className='toolbarBrandName'>ZUMI</h1> */}
      </a>

      {!isSignInPage && !isSignUpPage && (
        <div className="welcomeLinkAndBtn_cont">
          <div className="welcomeNavbarLinks_cont">
            <div className="welcomeNavLink_cont">
              <a className="welcomeNavLink" href="#marketplace">Marketplace</a>
            </div>
            <div className="welcomeNavLink_cont">
              <a className="welcomeNavLink" href="#wholesale">Wholesale</a>
            </div>
            <div className="welcomeNavLink_cont">
              <a className="welcomeNavLink" href="#consumer">Consumer</a>
            </div>
            <div className="welcomeNavLink_cont">
              <a className="welcomeNavLink" href="#pricing">Pricing</a>
            </div>
            <div className="welcomeNavLink_cont">
              <a className="welcomeNavLink" href="#contact">Book a Demo</a>
            </div>
          </div>
          <div className="welcomeNavbarButtons_cont">
            <div className="welcomeNavBtn_cont" style={{ marginRight: "10px" }}>
              <Link to="/signup" className="btnNotFill">Try it free</Link>
            </div>
            <div className="welcomeNavBtn_cont">
              <Link to="/signin" className="btnFill">Sign In</Link>
            </div>
          </div>
        </div>
      )}

      {/* M O B I L E */}

      <a href="/" className="welcomeNavBrand_contMob">
        <img src={zumiBrandImg} alt="brandImg" style={{ height: 40, width: 80 }} />
      </a>

      <div className="collapseBtn_contMob" onClick={toggleSidebar}>
        <div className="collapseBtn_cont">
          <DehazeIcon style={{ color: "rgb(14, 113, 195)" }} />
        </div>
        {/* Overlay */}
        <div className={`overlay ${isSidebarOpen ? 'overlay--visible' : ''}`} onClick={toggleSidebar}></div>
      </div>

      <div className={`welcomeSidebar_contMob ${isSidebarOpen ? 'sidebar--open' : ''}`} ref={sidebarRef}>
        <div className="welcomeNavbarLinks_contMob">
          <div className="welcomeNavLink_contMob">
            <div className="welcomeSidebarIconCont">
              <StoreIcon style={{ color: "brown" }} />
            </div>
            <a className="welcomeNavLink" href="#marketplace">Marketplace</a>
          </div>
          <div className="welcomeNavLink_contMob">
            <div className="welcomeSidebarIconCont">
              <SellIcon style={{ color: "red" }} />
            </div>
            <a className="welcomeNavLink" href="#wholesale">Wholesale</a>
          </div>
          <div className="welcomeNavLink_contMob">
            <div className="welcomeSidebarIconCont">
              <PeopleIcon style={{ color: "blue" }} />
            </div>
            <a className="welcomeNavLink" href="#consumer">Consumer</a>
          </div>
          <div className="welcomeNavLink_contMob">
            <div className="welcomeSidebarIconCont">
              <MoneyIcon style={{ color: "green" }} />
            </div>
            <a className="welcomeNavLink" href="#pricing">Pricing</a>
          </div>
          <div className="welcomeNavLink_contMob">
            <div className="welcomeSidebarIconCont">
              <FeedIcon style={{ color: "orange" }} />
            </div>
            <a className="welcomeNavLink" href="#contact">Book a Demo</a>
          </div>
        </div>
      </div>

    </div>
  )
}

export default WelcomeNavbar;
