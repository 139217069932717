import React, { useState, useEffect, useContext } from 'react';
import './BuyBox.css';
import Breadcrumbs from '../../Toolbar/Breadcrumbs';
import tableStyles from '../Revenue/TableStyles';
import LocationSelect from '../../Dashboard/LocationSelect/LocationSelect';
import ContactTableLoader from '../../Loaders/ContactTableLoader/ContactTableLoader';
import DataTable from 'react-data-table-component';

import { useTranslation } from 'react-i18next';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextField } from '@mui/material';

import TimelineIcon from '@mui/icons-material/Timeline';
import BuyboxChartContext from '../../../Context/BuyboxChartContext';
import SelectedMarketplaceContext from '../../../Context/SelectedMarketplaceContext';
import BuyboxChart from './BuyboxChart/BuyboxChart';

const BuyBox = () => {

  const { t } = useTranslation();
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const authToken = localStorage.getItem('authToken');
  const [buyboxData, setBuyboxData] = useState([]);

  const [totalQuantity, setTotalQuantity] = useState();
  const [totalValue, setTotalValue] = useState();
  const [buyboxLoader, setBuyboxLoader] = useState(true);

  const [currentDate, setCurrentDate] = useState(null);
  const [pastDate, setPastDate] = useState(null);
  const [weekDate, setWeekDate] = useState();

  const [roughStartDate, setRoughStartDate] = useState(null);
  const [roughEndDate, setRoughEndDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [combinedDate, setCombinedDate] = useState(null);

  const { buyboxChartArray, setBuyboxChartArray } = useContext(BuyboxChartContext);
  const { selectedMarketplace } = useContext(SelectedMarketplaceContext);

  const columns = [
    {
      name: t("marketplace"),
      selector: row => row.title,
      width: "120px",
    },
    {
      name: t("sku"),
      selector: row => row.sku,
      width: "170px",
    },
    {
      name: t("asin"),
      selector: row => {
        if (row.asin) {
          return row.asin
        }
        else {
          return row.sku
        }
      },
      width: "170px",
    },
    {
      name: t("productInfo"),
      selector: row => row.itemName,
      width: "250px",

    },
    {
      name: t("currencyCode"),
      selector: row => row.currencyCode,
      width: "90px",

    },
    {
      name: t("buyboxStatus"),
      selector: row => row.buyboxPrice,
      width: "120px",

    },
    {
      name: t("currentPrice"),
      selector: row => row.amount,
      width: "120px",

    },
    {
      name: t("lowestPriceAmazon"),
      selector: row => row.lowestPrice,
      width: "120px",

    },
  ];

  const handleStartChange = (date) => {
    setRoughStartDate(date);
  }
  const handleEndChange = (date) => {
    setRoughEndDate(date);
  }

  // // F U N C T I O N   T O   G E T   C U R R E N T   D A T E

  const getCurrentDate = () => {
    const today = new Date();
    today.setDate(today.getDate() - 1);

    // Extract individual components
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const day = today.getDate();
    const month = monthNames[today.getMonth()];
    const year = today.getFullYear();

    // Construct the date string without commas
    return `${month} ${day} ${year}`;
  };

  useEffect(() => {
    setCurrentDate(getCurrentDate());
  }, []);


  // // F U N C T I O N   T O   G E T  D A T E   O F   P A S T   7   D A Y S

  const getDateSevenDaysAgo = () => {
    const today = new Date();
    today.setDate(today.getDate() - 7);

    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const day = today.getDate();
    const month = monthNames[today.getMonth()];
    const year = today.getFullYear();

    return `${month} ${day} ${year}`;
    // return new Intl.DateTimeFormat('en-US', options).format(today);
  };

  useEffect(() => {
    setPastDate(getDateSevenDaysAgo());
  }, []);


  // // S E T   W E E K   D A T E

  useEffect(() => {
    if (pastDate && currentDate) {
      setWeekDate(pastDate + " - " + currentDate);
    }
  }, [pastDate, currentDate]);


  // // F U N T I O N   T O  S E T   R O U G H   D A T E   I N T O   F O R M A T T E D   D A T E

  useEffect(() => {
    if (roughStartDate) {
      const formattedStartDate = roughStartDate.format('MMM D YYYY');
      setStartDate(formattedStartDate);
    }
    if (roughEndDate) {
      const formattedEndDate = roughEndDate.format('MMM D YYYY');
      setEndDate(formattedEndDate);
    }
  }, [roughEndDate, roughStartDate]);

  //  F U N C T I O N   T O   C O M B I N E  D A T E S 
  useEffect(() => {
    if (startDate && endDate) {
      setCombinedDate(startDate + " - " + endDate)
      setWeekDate(null);
    }
  }, [startDate, endDate]);


  // // F E T C H    B U Y B O X    T A B L E   D A T A

  useEffect(() => {

    // WHEN NO DATE RANGE IS PASSED
    if (authToken && weekDate) {
      const buyboxTable_URL = `${baseUrl}/zumi/api/salesbuyboxproduct?dateRange=${weekDate}`;

      const fetchData = async () => {
        setBuyboxLoader(true);
        try {
          const response = await fetch(buyboxTable_URL, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${authToken}`, // Add the Authorization header
              'Content-Type': 'application/json',
            },
          });
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const result = await response.json();
          setBuyboxData(result);
        } catch (error) {
          console.log(error);
        } finally {
          setBuyboxLoader(false);
        }
      };
      fetchData();
    }


    // WHEN DATE RANGE IS PASSED

    if (authToken && combinedDate) {
      const buyboxTable_URL = `${baseUrl}/zumi/api/salesbuyboxproduct?dateRange=${combinedDate}`;
      const fetchData = async () => {
        setBuyboxLoader(true);
        try {
          const response = await fetch(buyboxTable_URL, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${authToken}`,
              'Content-Type': 'application/json',
            },
          });
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const result = await response.json();
          setBuyboxData(result);
        } catch (error) {
          console.log(error);
        } finally {
          setBuyboxLoader(false);
        }
      };
      fetchData();
    }
  }, [authToken, weekDate, combinedDate, selectedMarketplace]);


  // // F E T C H    B U Y B O X    C H A R T   D A T A

  // useEffect(() => {
  //   // WHEN NO DATE RANGE IS PASSED
  //   if (authToken && weekDate) {
  //   const buyboxTable_URL = `${baseUrl}/zumi/api/sales/buyboxchart?dateRange=${weekDate}`;
  //     const fetchData = async () => {
  //       try {
  //         const response = await fetch(buyboxTable_URL, {
  //           method: 'GET',
  //           headers: {
  //             'Authorization': `Bearer ${authToken}`, // Add the Authorization header
  //             'Content-Type': 'application/json',
  //           },
  //         });
  //         if (!response.ok) {
  //           throw new Error('Network response was not ok');
  //         }
  //         const result = await response.json();
  //         setBuyboxChartArray(result);
  //       } catch (error) {
  //         console.log(error);
  //       } finally {
  //       }
  //     };
  //     fetchData();
  //   }


  //   // WHEN DATE RANGE IS PASSED
  //   if (authToken && combinedDate) {
  //     const buyboxTable_URL = `${baseUrl}/zumi/api/sales/buyboxchart?dateRange=${combinedDate}`;

  //     const fetchData = async () => {
  //       try {
  //         const response = await fetch(buyboxTable_URL, {
  //           method: 'GET',
  //           headers: {
  //             'Authorization': `Bearer ${authToken}`, // Add the Authorization header
  //             'Content-Type': 'application/json',
  //           },
  //         });
  //         if (!response.ok) {
  //           throw new Error('Network response was not ok');
  //         }
  //         const result = await response.json();
  //         setBuyboxChartArray(result);
  //       } catch (error) {
  //         console.log(error);
  //       } finally {
  //       }
  //     };
  //     fetchData();
  //   }
  // }, [authToken, weekDate, combinedDate, selectedMarketplace]);


  return (
    <div className='revenue_main'>
      <Breadcrumbs />
      <div className="locationMain_cont">
        <LocationSelect />
        <div className="dateRangePickerMain_cont" style={{ display: 'flex', gap: '16px' }}>
          <LocalizationProvider dateAdapter={AdapterDayjs} >
            <DatePicker
              label="Start Date"
              value={roughStartDate}
              onChange={handleStartChange}
              maxDate={dayjs()}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="End Date"
              value={roughEndDate}
              onChange={handleEndChange}
              maxDate={dayjs()}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </div>
      </div>

      <div className="revenueTableMain_cont">
        <div className='revenueTable_cont'>
          {buyboxLoader ? (
            <ContactTableLoader />
          ) : (
            <DataTable
              columns={columns}
              data={buyboxData}
              customStyles={tableStyles}
              pagination
            />
          )}
        </div>
      </div>

    </div>
  )
}

export default BuyBox;