import React, { useState } from 'react';
import './NewText.css';
import { useTranslation } from 'react-i18next';

import { TextField } from '@mui/material';



const NewText = ({ closeModal }) => {
  const { t } = useTranslation();
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const [newMaterial, setNewMaterial] = useState({
    materialName: "",
    materialContent: "",
  })

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setNewMaterial((prev) => ({ ...prev, [name]: value }));
  }


  const handleSave = () => {
    console.log("New Material: ", newMaterial)
  }

  const isSaveDisabled = !newMaterial.materialName || !newMaterial.materialContent;

  return (
    <div className='newTextMain_cont'>
      <div className="newRoleHeader">
        <div className="templateMessage_cont">
          <span className='templateMessage'>
            {t('newTextMaterial')}
          </span>
        </div>
        <div className="closeBtn_cont" role="button" aria-label="Close" onClick={closeModal}>
          <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" className="bi bi-x crossSvg" viewBox="0 0 16 16">
            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
          </svg>
        </div>
      </div>
      <div className="newTextSubCont">
        <div className="newTextFormInput_cont" style={{ marginRight: "15px" }}>
          <label htmlFor="materialName" className="welcomeFormLabel">{t('materialName')}  <span className="asterisk">*</span></label>
          <TextField
            className='welcomeFormInput'
            InputLabelProps={{ style: { display: 'none' } }}
            inputProps={{ autoComplete: 'off' }}
            type='text'
            name="materialName"
            id="outlined-basic"
            placeholder="Type something..."
            variant="outlined"
            value={newMaterial.materialName}
            onChange={handleChange}
          />
        </div>

        <div className="newTextFormInput_cont" style={{ marginRight: "15px" }}>
          <label htmlFor="materialContent" className="welcomeFormLabel">{t('materialContent')}  <span className="asterisk">*</span></label>
          <TextField
            className='welcomeFormInput'
            InputLabelProps={{ style: { display: 'none' } }}
            inputProps={{ autoComplete: 'off' }}
            type='text'
            name="materialContent"
            id="outlined-basic"
            placeholder="Type something..."
            variant="outlined"
            multiline
            rows={3}
            value={newMaterial.materialContent}
            onChange={handleChange}
          />
        </div>

        <div className="newTextBtn_cont">
          <button
            className='btnFill'
            onClick={handleSave}
            disabled={isSaveDisabled}
            style={{
              border: isSaveDisabled ? "1px solid #E9EED9" : "#17c1e8",
              backgroundColor: isSaveDisabled ? '#E9EED9' : '#17c1e8',
              color: isSaveDisabled ? 'gray' : '#fff',
            }}
          >
            {t('save')}
          </button>
        </div>

      </div>
    </div>
  )
}

export default NewText;