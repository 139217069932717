import React from 'react';
import './Terms.css';
import Toolbar from '../Toolbar/Toolbar';
import WelcomeNavbar from '../Welcome/WelcomeNavbar/WelcomeNavbar';

const Terms = () => {
  const authToken = localStorage.getItem('authToken');
  return (
    <div className='termsMain_cont'>
      <div className="termsTool_cont">
        <div className="toolbarMain_cont">
          <div className="toolbarBrandMain_cont centerElm">
            <div className="toolBrandImg_cont">
              {/* <img src={ZumiBrandImg} alt="brandImg" /> */}
              <h1 className='toolbarBrandName'>ZUMI</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="termsInner_cont">
        <div className="termsSub_cont">
          <p className='termsMainHead'>Terms & Conditions</p>
          <div className="terms_cont">
            <p>This is an agreement between Zumi and any user of our websites including https://Zumi.au/ whereas use may or may not result in a purchase, order fulfilment and shipment of items.</p>
            <p>Please ensure you have read these terms and conditions</p>
          </div>

          <div className="terms_cont">
            <p className='termsBoldPara'>Legal</p>
            <p>Zumi is not liable for any loss or damages incurred for any person(s) who illegally use this website or illegal use of our services. Any user of this website is responsible for ensuring they use the website within laws that apply to them.</p>
            <p className='termsBoldPara'>Liability</p>
            <p>Zumi has necessary methods and protection in place for our customers to use our website safely and securely. We are not liable for any damages or losses incurred which may be perceived to be connected to use with our website. Users of our website should take necessary precautions to ensure they are browsing all websites safely and do so entirely at their own risk.</p>
            <p className="termsBoldPara">Third Party Websites</p>
            <p>We are not responsible for any incorrect information posted on third party websites referring to our website including but not limited to information relating to pricing, policies, terms and conditions or any other information.</p>
            <p>We are not bound to information posted on other websites nor are we responsible for getting incorrect information removed from third party websites.</p>
            <p className="termsBoldPara">Information on our Website </p>
            <p>We reserve the right to update pricing, product listings at any time without notice.</p>
            <p className="termsBoldPara">Legal Trade Name</p>
            <p>Zumi is the legal trading entity of Xtreme Communications Pty Ltd - ABN: 20152737516</p>
          </div>
        </div>


      </div>
    </div>
  )
}

export default Terms;