import React, { useState } from 'react';
import './SubmitContact.css'

import { useTranslation } from 'react-i18next';
import Breadcrumbs from '../../../../Toolbar/Breadcrumbs';

const SubmitContact = () => {

  const { t } = useTranslation();
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const [selectedFile, setSelectedFile] = useState(null);
  const [fileError, setFileError] = useState('');
  const authToken = localStorage.getItem('authToken');

  // H A N D L I N G   F I L E   S E L E C T I O N 
  const fileSelectedHandler = (event) => {
    const file = event.target.files[0]
    // setSelectedFile(file);

    if (file) {
      const fileName = file.name;
      const fileExtension = fileName.split('.').pop().toLowerCase(); // Get file extension

      // Check if the file extension is valid
      if (['csv', 'xlsx', 'xls'].includes(fileExtension)) {
        setFileError(''); // Clear error message
        setSelectedFile(file);
        // Proceed with processing the file
      } else {
        setFileError(t("invalidFileError"));
        event.target.value = ''; // Clear the input
      }
    }
  };


  // Handle file drop
  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const file = event.dataTransfer.files[0];
    setSelectedFile(file);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const uploadContact = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('contact', selectedFile);

    for (let entry of formData.entries()) {
      console.log(entry[0] + ': ' + entry[1]);
    }

    fetch(`${baseUrl}/zumi/api/importcontact`, {
      method: 'POST',
      body: formData,
      headers: {
        'Authorization': `Bearer ${authToken}`, // Add the Authorization header
      },
    }).then(response => {
      if (response.ok) { // Check if the response status is in the range 200-299
        console.log(response);
        alert(t("fileUploadAlert"))
      } else {
        // Handle different status codes
        alert(t("fileNotUploadAlert"));
        // throw new Error(message); // Optionally throw an error to stop further processing
      }
    })
    setSelectedFile(null);
  }


  return (
    <div className='submitContactMain_cont'>
      <Breadcrumbs />
      <div className="importContactHeading_cont">
        <h3 style={{ textAlign: "center" }}>{t('submitContactMainHead')}</h3>
      </div>
      <div className="contactsFileMain_cont">
        <h4>{t('contactsFile')}</h4>
        <div className="dragDropMain_cont" onDrop={handleDrop} onDragOver={handleDragOver}>
          <p>{t('dragDropParaHalf1')}
            <input type='file' accept=".csv, .xlsx, .xls" className='uploadContactFileBtn' name="contactFile" id="uploadBtn" onChange={fileSelectedHandler} />
            <label htmlFor="uploadBtn" className='uploadContactFileLabel'>{t('chooseFile')}</label>
            {t('dragDropParaHalf2')}</p>
          {selectedFile ? (
            <p style={{ marginTop: "10px", fontWeight: "500" }}>{t('selectedFile')}<span className='fileName'>{selectedFile.name}</span> </p>
          ) : <p style={{ marginTop: "10px", fontWeight: "500" }} className='nofile'>{t('noFileSelected')}</p>
          }
          {fileError && <p style={{ color: 'red' }}>{fileError}</p>}
        </div>

        <div className="uSubmit_cont">
          <button className='btnFill' onClick={uploadContact} disabled={!selectedFile || fileError}>{t('upload')}</button>
        </div>
      </div>


    </div>
  )
}

export default SubmitContact;